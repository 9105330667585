import React from "react";
import { createApi } from "../../../../utils/createApi";
import { AxiosResponse, AxiosError } from "axios";
import { GeneralInfoTab } from "../GeneralInfoTab";
import { VmsTab } from "../VmsTab";
import { checkIfAllCamerasDecativated } from "./checkIfAllCamerasDecativated";
import {
  AlertState,
  SiteInfo,
  SiteSchedule,
  SiteCameras,
} from "./siteAboutInterfaces";
import { NavigateFunction } from "react-router-dom";
import { checkArmedStatus } from "./checkArmedStatus";
import { universalAPIErrorHandler } from "../../../../utils/universalAPIErrorHandler";
import { getNumbersAfterAboutSite } from "../SiteAbout";

type Dispatcher<S> = React.Dispatch<React.SetStateAction<S>>;

export type HandleSyncSite = (
  setBackdropLoadingScreenOn: Dispatcher<boolean>,
  setAlertState: Dispatcher<AlertState>,
  siteInfo: SiteInfo,
  setSiteInfo: Dispatcher<SiteInfo>,
  setSyncNeeded: Dispatcher<boolean>,
  setAllSiteSchedules: Dispatcher<SiteSchedule[]>,
  setActiveTab: Dispatcher<JSX.Element>,
  vmsVariables: (string | string[] | undefined)[][],
  setSiteDeployed: Dispatcher<boolean>,
  setDeativateInquiryDialogOpen: Dispatcher<boolean>,
  setSiteCameras: Dispatcher<SiteCameras>,
  camerasNotDeployed: boolean,
  setCamerasNotDeployed: Dispatcher<boolean>,
  navigate: NavigateFunction,
  setVmsVariables: Dispatcher<(string | string[] | undefined)[][]>,
  setClickedTab: Dispatcher<string>
) => Promise<string | SiteSchedule[] | void>;

export const handleSyncSite = async (
  setBackdropLoadingScreenOn: Dispatcher<boolean>,
  setAlertState: Dispatcher<AlertState>,
  siteInfo: SiteInfo,
  setSiteInfo: Dispatcher<SiteInfo>,
  setSyncNeeded: Dispatcher<boolean>,
  setAllSiteSchedules: Dispatcher<SiteSchedule[]>,
  setActiveTab: Dispatcher<JSX.Element>,
  vmsVariables: (string | string[] | undefined)[][],
  setSiteDeployed: Dispatcher<boolean>,
  setDeativateInquiryDialogOpen: Dispatcher<boolean>,
  setSiteCameras: Dispatcher<SiteCameras>,
  camerasNotDeployed: boolean,
  setCamerasNotDeployed: Dispatcher<boolean>,
  navigate: NavigateFunction,
  setVmsVariables: Dispatcher<(string | string[] | undefined)[][]>,
  setClickedTab: Dispatcher<string>,
  setArmButtonDisabled: any,
  setArmedStatus: Dispatcher<boolean>
) => {
  setSyncNeeded(false);
  setSiteDeployed(true);
  const AxiosUI = createApi(``);
  setBackdropLoadingScreenOn(true);
  const retryFunction = () => {
    handleSyncSite(
      setBackdropLoadingScreenOn,
      setAlertState,
      siteInfo,
      setSiteInfo,
      setSyncNeeded,
      setAllSiteSchedules,
      setActiveTab,
      vmsVariables,
      setSiteDeployed,
      setDeativateInquiryDialogOpen,
      setSiteCameras,
      camerasNotDeployed,
      setCamerasNotDeployed,
      navigate,
      setVmsVariables,
      setClickedTab,
      setArmButtonDisabled,
      setArmedStatus
    );
  };
  let deployNextFail = false;
  let getSiteInfoFail = false;
  let getSiteScheduleFail = false;
  let getSiteCamerasFail = false;
  let getNVRCamerasFail = false;
  localStorage.setItem("noAutoAddCamerasDialogOpen", "true");

  await AxiosUI.post(
    `customer/${getNumbersAfterAboutSite(location.href)}/action/deploy_next/`
  ).then(
    () => {
      deployNextFail = false;
      localStorage.setItem("syncNeeded", "false");
      if (camerasNotDeployed) {
        setCamerasNotDeployed(false);
      }
    },
    (reason: AxiosError) => {
      deployNextFail = true;
      universalAPIErrorHandler(reason, navigate, retryFunction, () => {
        setBackdropLoadingScreenOn(false);
        setSyncNeeded(true);
        setSiteDeployed(false);
        alert(`Error Syncing site, please try again.`);
      });
    }
  );
  !deployNextFail &&
    (await AxiosUI.get(
      `customer/${getNumbersAfterAboutSite(location.href)}/about/`
    ).then(
      (response: AxiosResponse) => {
        getSiteInfoFail = false;
        localStorage.setItem("siteInfo", JSON.stringify(response.data));
        localStorage.setItem("integrationType", response.data.integration_type);
        setSiteInfo(response.data);
        checkArmedStatus(
          response.data,
          setArmButtonDisabled,
          navigate,
          setSiteInfo,
          setArmedStatus
        );
        setClickedTab("one");
        setActiveTab(
          <VmsTab vmsVariables={vmsVariables} siteInfo={siteInfo} />
        );
        setActiveTab(<GeneralInfoTab siteInfo={response.data} />);
        if (response.data.auto_add) {
          localStorage.setItem("autoAdd", "true");
        } else {
          localStorage.setItem("autoAdd", "false");
        }
      },
      (reason: AxiosError) => {
        getSiteInfoFail = true;
        universalAPIErrorHandler(reason, navigate, retryFunction, () => {
          setBackdropLoadingScreenOn(false);
        });
      }
    ));
  !deployNextFail &&
    !getSiteInfoFail &&
    (await AxiosUI.get(
      `schedule/by_customer/${getNumbersAfterAboutSite(location.href)}/`
    ).then(
      (response: AxiosResponse) => {
        getSiteScheduleFail = false;
        localStorage.setItem("siteSchedule", JSON.stringify(response.data));
        localStorage.setItem("siteId", localStorage.siteId);
        setAllSiteSchedules(response.data);
        setAlertState((previousState: AlertState) => {
          return {
            ...previousState,
            changeSuccess: false,
            activateSuccess: false,
            deactivateSuccess: false,
            disarmSuccess: false,
            armSuccess: false,
            syncSuccess: true,
            cameraDeleteSuccess: false,
            armFail: false,
          };
        });
      },
      (reason: AxiosError) => {
        getSiteScheduleFail = true;
        universalAPIErrorHandler(reason, navigate, retryFunction, () => {
          setBackdropLoadingScreenOn(false);
        });
      }
    ));
  !deployNextFail &&
    !getSiteInfoFail &&
    !getSiteScheduleFail &&
    (await AxiosUI.get(
      `camera/site/?customer__id=${getNumbersAfterAboutSite(location.href)}`
    ).then(
      (response: AxiosResponse) => {
        getSiteCamerasFail = false;
        localStorage.setItem("siteCameras", JSON.stringify(response.data));
        setSiteCameras(response.data);
        checkIfAllCamerasDecativated(
          response.data,
          setDeativateInquiryDialogOpen
        );
      },
      (reason: AxiosError) => {
        getSiteCamerasFail = true;
        universalAPIErrorHandler(reason, navigate, retryFunction, () => {
          setBackdropLoadingScreenOn(false);
        });
      }
    ));
  !deployNextFail &&
    !getSiteInfoFail &&
    !getSiteScheduleFail &&
    !getSiteCamerasFail &&
    (await AxiosUI.get(
      `nvr_camera/?customer__id=${getNumbersAfterAboutSite(location.href)}`
    ).then(
      (response: AxiosResponse) => {
        if (response.data.count === 0) {
          localStorage.setItem("noAutoAddCamerasDialogOpen", "true");
        }
        if (response.data.count > 0) {
          localStorage.setItem("noAutoAddCamerasDialogOpen", "false");
        }
        getNVRCamerasFail = false;
      },
      (reason: AxiosError) => {
        getNVRCamerasFail = true;
        universalAPIErrorHandler(reason, navigate, retryFunction, () => {});
      }
    ));
  !deployNextFail &&
    !getSiteInfoFail &&
    !getSiteScheduleFail &&
    !getSiteCamerasFail &&
    !getNVRCamerasFail &&
    (await AxiosUI.get(
      `nvr_camera/extra_fields/?customer__id=${getNumbersAfterAboutSite(
        location.href
      )}`
    ).then(
      (response: AxiosResponse) => {
        setBackdropLoadingScreenOn(false);
        localStorage.setItem(
          "additionalFieldNamesArray",
          JSON.stringify(response.data)
        );
      },
      (reason: AxiosError) => {
        universalAPIErrorHandler(reason, navigate, retryFunction, () => {
          setBackdropLoadingScreenOn(false);
        });
      }
    ));
};
