import React from "react";
import { AxiosError, AxiosResponse } from "axios";
import { createMonitoringAPICall } from "./createMonitoringAPICall";
import { fetchGroupTrees } from "./fetchGroupTrees";

export const getRefreshTimeout = (id: number) => {
  const AxiosUI = createMonitoringAPICall(``);

  AxiosUI.get(`group/${id}`).then(
    (response: AxiosResponse) => {
      localStorage.setItem(
        "refresh_interval",
        JSON.stringify(response.data.refresh_interval_seconds * 1000)
      );
      return response.data.refresh_interval_seconds * 1000;
    },
    (reason: AxiosError) => {
      // eslint-disable-next-line no-console
      console.log("error reason:", reason);
      localStorage.setItem("refresh_interval", "10000");
      return 10000;
    }
  );
};
