import { GeneralInfoEditCameraTab } from "../GeneralInfoEditCameraTab";
import { ConnectionsTab } from "../ConnectionsTab";
import { NotificationsTab } from "../NotificationsTab";
import { ProductsTab } from "../ProductsTab";
import { ImmixAlertsTab } from "../ImmixAlertsTab";
import { SureviewAlertsTab } from "../SureviewAlertsTab";
import { Dispatcher } from "../../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { NavigateFunction } from "react-router-dom";
import { AxisTab } from "../AxisTab";
import { MobotixTab } from "../MobotixTab";
import ErrorBoundary from "../../../../ErrorBoundary";
import { WebhooksCameraTab } from "../WebhooksCameraTab";
import { SentinelAlertsTab } from "../SentinelAlertsTab";
import { BoldAlertsTab } from "../BoldAlertsTab";
import { PatriotAlertsTab } from "../PatriotAlertsTab";
import { USMonitoringAlertsTab } from "../USMonitoringAlertsTab";

export const handleEditCameraCancelButtonClick = (
  setEditMode: any,
  clickedTab: any,
  setActiveTab: any,
  siteInfo: any,
  cameraGeneralInfo: any,
  setCameraGeneralInfo: any,
  cameraGeneralInfoBackup: any,
  setPatchObject: any,
  immixAlerts: any,
  sureviewAlerts: any,
  setAddImmixAlertDialogOpen: any,
  setAddSureviewAlertDialogOpen: any,
  activeProducts: any,
  setBackdropLoadingScreenOn: any,
  setSendingImmixTest: Dispatcher<boolean>,
  setImmixTestSuccessDialogOpen: Dispatcher<boolean>,
  setImmixTestFailDialogOpen: Dispatcher<boolean>,
  recipientsConfiguredData: any,
  titlesArray: any,
  setRecipientsConfiguredData: any,
  setTitlesArray: any,
  actionRulesData: any,
  setActionRulesData: any,
  actionRulesTitlesArray: any,
  setActionRulesTitlesArray: any,
  applicationsConfiguredData: any,
  axisFinished: any,
  timeTablesData: any,
  setTimeTablesData: any,
  iPNotifyData: any,
  setIPNotifyData: any,
  actionHandlerData: any,
  setActionHandlerData: any,
  cameraWebhookData: any,
  productsData: any,
  setProductsData: Dispatcher<any>,
  allTabsOpen: boolean,
  setAllTabsOpen: Dispatcher<boolean>,
  backupProductsData: any,
  setBackupProductsData: any,
  sentinelAlerts: any,
  setAddSentinelAlertDialogOpen: any,
  boldAlerts: any,
  setAddBoldAlertDialogOpen: Dispatcher<boolean>,
  patriotAlerts: any,
  setAddPatriotAlertDialogOpen: Dispatcher<boolean>,
  uSMonitoringAlerts: any,
  setAddUSMonitoringAlertDialogOpen: Dispatcher<boolean>,
  navigate: NavigateFunction
) => {
  setProductsData(backupProductsData);
  setEditMode(false);
  setCameraGeneralInfo(cameraGeneralInfoBackup);
  setPatchObject({
    camera_name: cameraGeneralInfoBackup.camera_name,
    streams: cameraGeneralInfoBackup.streams,
  });
  if (clickedTab === "one") {
    setActiveTab(
      <GeneralInfoEditCameraTab
        siteInfo={siteInfo}
        cameraGeneralInfo={cameraGeneralInfoBackup}
      />
    );
  } else if (clickedTab === "two") {
    setActiveTab(
      <ConnectionsTab cameraGeneralInfo={cameraGeneralInfoBackup} />
    );
  } else if (clickedTab === "three") {
    setActiveTab(
      <NotificationsTab cameraGeneralInfo={cameraGeneralInfoBackup} />
    );
  } else if (clickedTab === "four") {
    setProductsData(JSON.parse(localStorage.backupProductsData));
    setActiveTab(
      <ProductsTab
        productsData={JSON.parse(localStorage.backupProductsData)}
        setProductsData={setProductsData}
        allTabsOpen={allTabsOpen}
        setAllTabsOpen={setAllTabsOpen}
        setBackupProductsData={setBackupProductsData}
      />
    );
  } else if (clickedTab === "five") {
    setActiveTab(
      <ImmixAlertsTab
        immixAlerts={immixAlerts}
        setAddImmixAlertDialogOpen={setAddImmixAlertDialogOpen}
        setSendingImmixTest={setSendingImmixTest}
        setImmixTestSuccessDialogOpen={setImmixTestSuccessDialogOpen}
        setImmixTestFailDialogOpen={setImmixTestFailDialogOpen}
        navigate={navigate}
      />
    );
  } else if (clickedTab === "six") {
    setActiveTab(
      <SureviewAlertsTab
        sureviewAlerts={sureviewAlerts}
        setAddSureviewAlertDialogOpen={setAddSureviewAlertDialogOpen}
      />
    );
  } else if (clickedTab === "seven") {
    setActiveTab(
      <AxisTab
        recipientsConfiguredData={recipientsConfiguredData}
        titlesArray={titlesArray}
        navigate={navigate}
        setBackdropLoadingScreenOn={setBackdropLoadingScreenOn}
        setRecipientsConfiguredData={setRecipientsConfiguredData}
        setTitlesArray={setTitlesArray}
        actionRulesData={actionRulesData}
        setActionRulesData={setActionRulesData}
        actionRulesTitlesArray={actionRulesTitlesArray}
        setActionRulesTitlesArray={setActionRulesTitlesArray}
        setActiveTab={setActiveTab}
        applicationsConfiguredData={applicationsConfiguredData}
        cameraGeneralInfo={cameraGeneralInfo}
        axisFinished={axisFinished}
      />
    );
  } else if (clickedTab === "eight") {
    setActiveTab(
      <MobotixTab
        timeTablesData={timeTablesData}
        navigate={navigate}
        setBackdropLoadingScreenOn={setBackdropLoadingScreenOn}
        setTimeTablesData={setTimeTablesData}
        setActiveTab={setActiveTab}
        iPNotifyData={iPNotifyData}
        setIPNotifyData={setIPNotifyData}
        actionHandlerData={actionHandlerData}
        setActionHandlerData={setActionHandlerData}
      />
    );
  } else if (clickedTab === "nine") {
    setActiveTab(
      <ErrorBoundary>
        <WebhooksCameraTab cameraWebhookData={cameraWebhookData} />
      </ErrorBoundary>
    );
  } else if (clickedTab === "ten") {
    setActiveTab(
      <SentinelAlertsTab
        sentinelAlerts={sentinelAlerts}
        setAddSentinelAlertDialogOpen={setAddSentinelAlertDialogOpen}
      />
    );
  } else if (clickedTab === "eleven") {
    setActiveTab(
      <BoldAlertsTab
        boldAlerts={boldAlerts}
        setAddBoldAlertDialogOpen={setAddBoldAlertDialogOpen}
      />
    );
  } else if (clickedTab === "twelve") {
    setActiveTab(
      <PatriotAlertsTab
        patriotAlerts={patriotAlerts}
        setAddPatriotAlertDialogOpen={setAddPatriotAlertDialogOpen}
      />
    );
  } else if (clickedTab === "thirteen") {
    setActiveTab(
      <USMonitoringAlertsTab
        uSMonitoringAlerts={uSMonitoringAlerts}
        setAddUSMonitoringAlertDialogOpen={setAddUSMonitoringAlertDialogOpen}
      />
    );
  }
  setBackdropLoadingScreenOn(false);
};
