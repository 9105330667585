import React from "react";
import { OrangeTextButton } from "../../common/OrangeTextButton";
import { handleEditCameraCancelButtonClick } from "./EditCameraUtils/handleEditCameraCancelButtonClick";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { NavigateFunction } from "react-router-dom";

export const EditCameraCancelButton = ({
  setEditMode,
  clickedTab,
  setActiveTab,
  siteInfo,
  cameraGeneralInfo,
  setCameraGeneralInfo,
  cameraGeneralInfoBackup,
  setPatchObject,
  immixAlerts,
  sureviewAlerts,
  setAddImmixAlertDialogOpen,
  setAddSureviewAlertDialogOpen,
  activeProducts,
  setBackdropLoadingScreenOn,
  setSendingImmixTest,
  setImmixTestSuccessDialogOpen,
  setImmixTestFailDialogOpen,
  recipientsConfiguredData,
  titlesArray,
  setRecipientsConfiguredData,
  setTitlesArray,
  actionRulesData,
  setActionRulesData,
  actionRulesTitlesArray,
  setActionRulesTitlesArray,
  applicationsConfiguredData,
  axisFinished,
  timeTablesData,
  setTimeTablesData,
  iPNotifyData,
  setIPNotifyData,
  actionHandlerData,
  setActionHandlerData,
  cameraWebhookData,
  productsData,
  setProductsData,
  allTabsOpen,
  setAllTabsOpen,
  backupProductsData,
  setBackupProductsData,
  sentinelAlerts,
  setAddSentinelAlertDialogOpen,
  boldAlerts,
  setAddBoldAlertDialogOpen,
  patriotAlerts,
  setAddPatriotAlertDialogOpen,
  uSMonitoringAlerts,
  setAddUSMonitoringAlertDialogOpen,
  navigate,
}: {
  setEditMode: any;
  clickedTab: any;
  setActiveTab: any;
  siteInfo: any;
  cameraGeneralInfo: any;
  setCameraGeneralInfo: any;
  cameraGeneralInfoBackup: any;
  setPatchObject: any;
  immixAlerts: any;
  sureviewAlerts: any;
  setAddImmixAlertDialogOpen: any;
  setAddSureviewAlertDialogOpen: any;
  activeProducts: any;
  setBackdropLoadingScreenOn: any;
  setSendingImmixTest: Dispatcher<boolean>;
  setImmixTestSuccessDialogOpen: Dispatcher<boolean>;
  setImmixTestFailDialogOpen: Dispatcher<boolean>;
  recipientsConfiguredData: any;
  titlesArray: any;
  setRecipientsConfiguredData: any;
  setTitlesArray: any;
  actionRulesData: any;
  setActionRulesData: any;
  actionRulesTitlesArray: any;
  setActionRulesTitlesArray: any;
  applicationsConfiguredData: any;
  axisFinished: any;
  timeTablesData: any;
  setTimeTablesData: any;
  iPNotifyData: any;
  setIPNotifyData: any;
  actionHandlerData: any;
  setActionHandlerData: any;
  cameraWebhookData: any;
  productsData: any;
  setProductsData: Dispatcher<any>;
  allTabsOpen: boolean;
  setAllTabsOpen: Dispatcher<boolean>;
  backupProductsData: any;
  setBackupProductsData: any;
  sentinelAlerts: any;
  setAddSentinelAlertDialogOpen: any;
  boldAlerts: any;
  setAddBoldAlertDialogOpen: Dispatcher<boolean>;
  patriotAlerts: any;
  setAddPatriotAlertDialogOpen: Dispatcher<boolean>;
  uSMonitoringAlerts: any;
  setAddUSMonitoringAlertDialogOpen: Dispatcher<boolean>;
  navigate: NavigateFunction;
}) => {
  return (
    <div>
      <OrangeTextButton
        onClick={() => {
          handleEditCameraCancelButtonClick(
            setEditMode,
            clickedTab,
            setActiveTab,
            siteInfo,
            cameraGeneralInfo,
            setCameraGeneralInfo,
            cameraGeneralInfoBackup,
            setPatchObject,
            immixAlerts,
            sureviewAlerts,
            setAddImmixAlertDialogOpen,
            setAddSureviewAlertDialogOpen,
            activeProducts,
            setBackdropLoadingScreenOn,
            setSendingImmixTest,
            setImmixTestSuccessDialogOpen,
            setImmixTestFailDialogOpen,
            recipientsConfiguredData,
            titlesArray,
            setRecipientsConfiguredData,
            setTitlesArray,
            actionRulesData,
            setActionRulesData,
            actionRulesTitlesArray,
            setActionRulesTitlesArray,
            applicationsConfiguredData,
            axisFinished,
            timeTablesData,
            setTimeTablesData,
            iPNotifyData,
            setIPNotifyData,
            actionHandlerData,
            setActionHandlerData,
            cameraWebhookData,
            productsData,
            setProductsData,
            allTabsOpen,
            setAllTabsOpen,
            backupProductsData,
            setBackupProductsData,
            sentinelAlerts,
            setAddSentinelAlertDialogOpen,
            boldAlerts,
            setAddBoldAlertDialogOpen,
            patriotAlerts,
            setAddPatriotAlertDialogOpen,
            uSMonitoringAlerts,
            setAddUSMonitoringAlertDialogOpen,
            navigate
          );
        }}
        text="CANCEL"
        disabled={false}
      />
    </div>
  );
};
