import React from "react";
import { GeneralInfoEditCameraTab } from "../GeneralInfoEditCameraTab";
import { GeneralInfoEditCameraTabEditMode } from "../GeneralInfoEditCameraTabEditMode";
import { ConnectionsTab } from "../ConnectionsTab";
import { ConnectionsTabEditMode } from "../ConnectionsTabEditMode";
import { NotificationsTab } from "../NotificationsTab";
import { NotificationsTabEditMode } from "../NotificationsTabEditMode";
import { ProductsTab } from "../ProductsTab";
import { ProductsTabEditMode } from "../ProductsTabEditMode";
import { ImmixAlertsTab } from "../ImmixAlertsTab";
import { ImmixAlertsTabEditMode } from "../ImmixAlertsTabEditMode";
import { SureviewAlertsTab } from "../SureviewAlertsTab";
import { SureviewAlertsTabEditMode } from "../SureviewAlertsTabEditMode";
import { AxisTab } from "../AxisTab";
import { NavigateFunction } from "react-router-dom";
import { Dispatcher } from "../../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { MobotixTab } from "../MobotixTab";
import { WebhooksCameraTab } from "../WebhooksCameraTab";
import ErrorBoundary from "../../../../ErrorBoundary";
import { WebhooksCameraTabEditMode } from "../WebhooksCameraTabEditMode";
import { SentinelAlertsTab } from "../SentinelAlertsTab";
import { SentinelAlertsTabEditMode } from "../SentinelAlertsTabEditMode";
import { BoldAlertsTab } from "../BoldAlertsTab";
import { BoldAlertsTabEditMode } from "../BoldAlertsTabEditMode";
import { PatriotAlertsTab } from "../PatriotAlertsTab";
import { PatriotAlertsTabEditMode } from "../PatriotAlertsTabEditMode";
import { USMonitoringAlertsTab } from "../USMonitoringAlertsTab";
import { USMonitoringAlertsTabEditMode } from "../USMonitoringAlertsTabEditMode";

export const handleTabChangeEditCamera = (
  tabValue: any,
  editMode: boolean,
  setClickedTab: any,
  setActiveTab: any,
  siteInfo: any,
  cameraGeneralInfo: any,
  setCameraGeneralInfo: any,
  setPatchObject: any,
  setGeneralInfoChangesMade: any,
  immixAlerts: any,
  setActiveChangeMade: any,
  sureviewAlerts: any,
  setAddImmixAlertDialogOpen: any,
  setAddSureviewAlertDialogOpen: any,
  streamsObject: any,
  activeProducts: any,
  recipientsConfiguredData: any,
  titlesArray: any,
  navigate: NavigateFunction,
  setBackdropLoadingScreenOn: Dispatcher<boolean>,
  setRecipientsConfiguredData: any,
  setTitlesArray: any,
  actionRulesData: any,
  setActionRulesData: Dispatcher<any>,
  actionRulesTitlesArray: any,
  setActionRulesTitlesArray: Dispatcher<any>,
  applicationsConfiguredData: any,
  timeTablesData: any,
  setTimeTablesData: any,
  iPNotifyData: any,
  setIPNotifyData: any,
  actionHandlerData: any,
  setActionHandlerData: any,
  axisFinished: any,
  setSendingImmixTest: Dispatcher<boolean>,
  setImmixTestSuccessDialogOpen: Dispatcher<boolean>,
  setImmixTestFailDialogOpen: Dispatcher<boolean>,
  cameraWebhookData: any,
  setCameraWebhooksPatchObject: any,
  setCameraWebhooksChangesMade: Dispatcher<boolean>,
  productsData: any,
  setProductsData: Dispatcher<any>,
  allTabsOpen: boolean,
  setAllTabsOpen: Dispatcher<boolean>,
  setBackupProductsData: any,
  sentinelAlerts: any,
  setAddSentinelAlertDialogOpen: any,
  boldAlerts: any,
  setAddBoldAlertDialogOpen: Dispatcher<boolean>,
  patriotAlerts: any,
  setAddPatriotAlertDialogOpen: Dispatcher<boolean>,
  uSMonitoringAlerts: any,
  setAddUSMonitoringAlertDialogOpen: Dispatcher<boolean>
) => {
  setClickedTab(tabValue);
  localStorage.setItem("editCamerActiveTab", tabValue);
  if (tabValue === "one" && !editMode) {
    setActiveTab(
      <ErrorBoundary>
        <GeneralInfoEditCameraTab
          siteInfo={siteInfo}
          cameraGeneralInfo={cameraGeneralInfo}
        />
      </ErrorBoundary>
    );
  } else if (tabValue === "one" && editMode) {
    setActiveTab(
      <ErrorBoundary>
        <GeneralInfoEditCameraTabEditMode
          siteInfo={siteInfo}
          cameraGeneralInfo={cameraGeneralInfo}
          setCameraGeneralInfo={setCameraGeneralInfo}
          setPatchObject={setPatchObject}
          setGeneralInfoChangesMade={setGeneralInfoChangesMade}
          setActiveChangeMade={setActiveChangeMade}
        />
      </ErrorBoundary>
    );
  } else if (tabValue === "two" && !editMode) {
    setActiveTab(
      <ErrorBoundary>
        <ConnectionsTab cameraGeneralInfo={cameraGeneralInfo} />{" "}
      </ErrorBoundary>
    );
  } else if (tabValue === "two" && editMode) {
    setActiveTab(
      <ErrorBoundary>
        <ConnectionsTabEditMode
          cameraGeneralInfo={cameraGeneralInfo}
          setCameraGeneralInfo={setCameraGeneralInfo}
          setPatchObject={setPatchObject}
          setGeneralInfoChangesMade={setGeneralInfoChangesMade}
        />
      </ErrorBoundary>
    );
  } else if (tabValue === "three" && !editMode) {
    setActiveTab(
      <ErrorBoundary>
        <NotificationsTab cameraGeneralInfo={cameraGeneralInfo} />
      </ErrorBoundary>
    );
  } else if (tabValue === "three" && editMode) {
    setActiveTab(
      <ErrorBoundary>
        <NotificationsTabEditMode
          cameraGeneralInfo={cameraGeneralInfo}
          setCameraGeneralInfo={setCameraGeneralInfo}
          setPatchObject={setPatchObject}
          setGeneralInfoChangesMade={setGeneralInfoChangesMade}
        />
      </ErrorBoundary>
    );
  } else if (tabValue === "four" && !editMode) {
    setActiveTab(
      <ErrorBoundary>
        <ProductsTab
          productsData={productsData}
          setProductsData={setProductsData}
          allTabsOpen={allTabsOpen}
          setAllTabsOpen={setAllTabsOpen}
          setBackupProductsData={setBackupProductsData}
        />
      </ErrorBoundary>
    );
  } else if (tabValue === "four" && editMode) {
    setActiveTab(
      <ErrorBoundary>
        <ProductsTabEditMode
          activeProducts={activeProducts}
          setPatchObject={setPatchObject}
          setGeneralInfoChangesMade={setGeneralInfoChangesMade}
          cameraGeneralInfo={cameraGeneralInfo}
          productsData={productsData}
          setProductsData={setProductsData}
          allTabsOpen={allTabsOpen}
          setAllTabsOpen={setAllTabsOpen}
          setBackupProductsData={setBackupProductsData}
        />
      </ErrorBoundary>
    );
  } else if (tabValue === "five" && !editMode) {
    setActiveTab(
      <ErrorBoundary>
        <ImmixAlertsTab
          immixAlerts={immixAlerts}
          setAddImmixAlertDialogOpen={setAddImmixAlertDialogOpen}
          setSendingImmixTest={setSendingImmixTest}
          setImmixTestSuccessDialogOpen={setImmixTestSuccessDialogOpen}
          setImmixTestFailDialogOpen={setImmixTestFailDialogOpen}
          navigate={navigate}
        />
      </ErrorBoundary>
    );
  } else if (tabValue === "five" && editMode) {
    setActiveTab(
      <ErrorBoundary>
        <ImmixAlertsTabEditMode
          immixAlerts={immixAlerts}
          cameraGeneralInfo={cameraGeneralInfo}
          setPatchObject={setPatchObject}
          setGeneralInfoChangesMade={setGeneralInfoChangesMade}
        />
      </ErrorBoundary>
    );
  } else if (tabValue === "six" && !editMode) {
    setActiveTab(
      <ErrorBoundary>
        <SureviewAlertsTab
          sureviewAlerts={sureviewAlerts}
          setAddSureviewAlertDialogOpen={setAddSureviewAlertDialogOpen}
        />
      </ErrorBoundary>
    );
  } else if (tabValue === "six" && editMode) {
    setActiveTab(
      <ErrorBoundary>
        <SureviewAlertsTabEditMode
          sureviewAlerts={sureviewAlerts}
          cameraGeneralInfo={cameraGeneralInfo}
          setPatchObject={setPatchObject}
          setGeneralInfoChangesMade={setGeneralInfoChangesMade}
        />
      </ErrorBoundary>
    );
  } else if (tabValue === "seven") {
    setActiveTab(
      <ErrorBoundary>
        <AxisTab
          recipientsConfiguredData={recipientsConfiguredData}
          titlesArray={titlesArray}
          navigate={navigate}
          setBackdropLoadingScreenOn={setBackdropLoadingScreenOn}
          setRecipientsConfiguredData={setRecipientsConfiguredData}
          setTitlesArray={setTitlesArray}
          actionRulesData={actionRulesData}
          setActionRulesData={setActionRulesData}
          actionRulesTitlesArray={actionRulesTitlesArray}
          setActionRulesTitlesArray={setActionRulesTitlesArray}
          setActiveTab={setActiveTab}
          applicationsConfiguredData={applicationsConfiguredData}
          cameraGeneralInfo={cameraGeneralInfo}
          axisFinished={axisFinished}
        />
      </ErrorBoundary>
    );
  } else if (tabValue === "eight") {
    setActiveTab(
      <ErrorBoundary>
        <MobotixTab
          timeTablesData={timeTablesData}
          navigate={navigate}
          setBackdropLoadingScreenOn={setBackdropLoadingScreenOn}
          setTimeTablesData={setTimeTablesData}
          setActiveTab={setActiveTab}
          iPNotifyData={iPNotifyData}
          setIPNotifyData={setIPNotifyData}
          actionHandlerData={actionHandlerData}
          setActionHandlerData={setActionHandlerData}
        />
      </ErrorBoundary>
    );
  } else if (tabValue === "nine" && !editMode) {
    setActiveTab(
      <ErrorBoundary>
        <WebhooksCameraTab cameraWebhookData={cameraWebhookData} />
      </ErrorBoundary>
    );
  } else if (tabValue === "nine" && editMode) {
    setActiveTab(
      <ErrorBoundary>
        <WebhooksCameraTabEditMode
          cameraWebhookData={cameraWebhookData}
          setCameraWebhooksPatchObject={setCameraWebhooksPatchObject}
          setCameraWebhooksChangesMade={setCameraWebhooksChangesMade}
        />
      </ErrorBoundary>
    );
  } else if (tabValue === "ten" && !editMode) {
    setActiveTab(
      <ErrorBoundary>
        <SentinelAlertsTab
          sentinelAlerts={sentinelAlerts}
          setAddSentinelAlertDialogOpen={setAddSentinelAlertDialogOpen}
        />
      </ErrorBoundary>
    );
  } else if (tabValue === "ten" && editMode) {
    setActiveTab(
      <ErrorBoundary>
        <SentinelAlertsTabEditMode
          sentinelAlerts={sentinelAlerts}
          cameraGeneralInfo={cameraGeneralInfo}
          setPatchObject={setPatchObject}
          setGeneralInfoChangesMade={setGeneralInfoChangesMade}
        />
      </ErrorBoundary>
    );
  } else if (tabValue === "eleven" && !editMode) {
    setActiveTab(
      <ErrorBoundary>
        <BoldAlertsTab
          boldAlerts={boldAlerts}
          setAddBoldAlertDialogOpen={setAddBoldAlertDialogOpen}
        />
      </ErrorBoundary>
    );
  } else if (tabValue === "eleven" && editMode) {
    setActiveTab(
      <ErrorBoundary>
        <BoldAlertsTabEditMode
          boldAlerts={boldAlerts}
          cameraGeneralInfo={cameraGeneralInfo}
          setPatchObject={setPatchObject}
          setGeneralInfoChangesMade={setGeneralInfoChangesMade}
        />
      </ErrorBoundary>
    );
  } else if (tabValue === "twelve" && !editMode) {
    setActiveTab(
      <ErrorBoundary>
        <PatriotAlertsTab
          patriotAlerts={patriotAlerts}
          setAddPatriotAlertDialogOpen={setAddPatriotAlertDialogOpen}
        />
      </ErrorBoundary>
    );
  } else if (tabValue === "twelve" && editMode) {
    setActiveTab(
      <ErrorBoundary>
        <PatriotAlertsTabEditMode
          patriotAlerts={patriotAlerts}
          cameraGeneralInfo={cameraGeneralInfo}
          setPatchObject={setPatchObject}
          setGeneralInfoChangesMade={setGeneralInfoChangesMade}
        />
      </ErrorBoundary>
    );
  } else if (tabValue === "thirteen" && !editMode) {
    setActiveTab(
      <ErrorBoundary>
        <USMonitoringAlertsTab
          uSMonitoringAlerts={uSMonitoringAlerts}
          setAddUSMonitoringAlertDialogOpen={setAddUSMonitoringAlertDialogOpen}
        />
      </ErrorBoundary>
    );
  } else if (tabValue === "thirteen" && editMode) {
    setActiveTab(
      <ErrorBoundary>
        <USMonitoringAlertsTabEditMode
          uSMonitoringAlerts={uSMonitoringAlerts}
          cameraGeneralInfo={cameraGeneralInfo}
          setPatchObject={setPatchObject}
          setGeneralInfoChangesMade={setGeneralInfoChangesMade}
        />
      </ErrorBoundary>
    );
  }
};
