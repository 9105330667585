import React from "react";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { handleTabChangeEditCamera } from "./EditCameraUtils/handleTabChangeEditCamera";
import { NavigateFunction } from "react-router-dom";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";

const theme = createTheme({
  palette: {
    primary: {
      main: "#FF8400",
    },
  },
});

export const EditCameraTabs = ({
  clickedTab,
  setClickedTab,
  editMode,
  setActiveTab,
  siteInfo,
  cameraGeneralInfo,
  setCameraGeneralInfo,
  setPatchObject,
  setGeneralInfoChangesMade,
  immixAlertsOnCamera,
  sureViewAlertsOnCamera,
  immixAlerts,
  setActiveChangeMade,
  sureviewAlerts,
  setAddImmixAlertDialogOpen,
  setAddSureviewAlertDialogOpen,
  streamsObject,
  setActiveProducts,
  activeProducts,
  axisCamera,
  recipientsConfiguredData,
  titlesArray,
  navigate,
  setBackdropLoadingScreenOn,
  setRecipientsConfiguredData,
  setTitlesArray,
  actionRulesData,
  setActionRulesData,
  actionRulesTitlesArray,
  setActionRulesTitlesArray,
  applicationsConfiguredData,
  mobotixCamera,
  timeTablesData,
  setTimeTablesData,
  iPNotifyData,
  setIPNotifyData,
  actionHandlerData,
  setActionHandlerData,
  axisFinished,
  setSendingImmixTest,
  setImmixTestSuccessDialogOpen,
  setImmixTestFailDialogOpen,
  cameraWebhookData,
  setCameraWebhooksPatchObject,
  setCameraWebhooksChangesMade,
  productsData,
  setProductsData,
  allTabsOpen,
  setAllTabsOpen,
  setBackupProductsData,
  sentinelAlertsOnCamera,
  sentinelAlerts,
  setAddSentinelAlertDialogOpen,
  boldAlertsOnCamera,
  boldAlerts,
  setAddBoldAlertDialogOpen,
  patriotAlertsOnCamera,
  patriotAlerts,
  setAddPatriotAlertDialogOpen,
  uSMonitoringAlertsOnCamera,
  uSMonitoringAlerts,
  setAddUSMonitoringAlertDialogOpen,
}: {
  clickedTab: any;
  setClickedTab: any;
  editMode: any;
  setActiveTab: any;
  siteInfo: any;
  cameraGeneralInfo: any;
  setCameraGeneralInfo: any;
  setPatchObject: any;
  setGeneralInfoChangesMade: any;
  immixAlertsOnCamera: boolean;
  sureViewAlertsOnCamera: boolean;
  immixAlerts: any;
  setActiveChangeMade: any;
  sureviewAlerts: any;
  setAddImmixAlertDialogOpen: any;
  setAddSureviewAlertDialogOpen: any;
  streamsObject: any;
  setActiveProducts: any;
  activeProducts: any;
  axisCamera: boolean;
  recipientsConfiguredData: any;
  titlesArray: any;
  navigate: NavigateFunction;
  setBackdropLoadingScreenOn: Dispatcher<boolean>;
  setRecipientsConfiguredData: any;
  setTitlesArray: any;
  actionRulesData: any;
  setActionRulesData: Dispatcher<any>;
  actionRulesTitlesArray: any;
  setActionRulesTitlesArray: Dispatcher<any>;
  applicationsConfiguredData: any;
  mobotixCamera: boolean;
  timeTablesData: any;
  setTimeTablesData: Dispatcher<any>;
  iPNotifyData: any;
  setIPNotifyData: any;
  actionHandlerData: any;
  setActionHandlerData: any;
  axisFinished: any;
  setSendingImmixTest: Dispatcher<boolean>;
  setImmixTestSuccessDialogOpen: Dispatcher<boolean>;
  setImmixTestFailDialogOpen: Dispatcher<boolean>;
  cameraWebhookData: any;
  setCameraWebhooksPatchObject: any;
  setCameraWebhooksChangesMade: Dispatcher<boolean>;
  productsData: any;
  setProductsData: Dispatcher<any>;
  allTabsOpen: boolean;
  setAllTabsOpen: Dispatcher<boolean>;
  setBackupProductsData: any;
  sentinelAlertsOnCamera: boolean;
  sentinelAlerts: any;
  setAddSentinelAlertDialogOpen: any;
  boldAlertsOnCamera: boolean;
  boldAlerts: any;
  setAddBoldAlertDialogOpen: Dispatcher<boolean>;
  patriotAlertsOnCamera: boolean;
  patriotAlerts: any;
  setAddPatriotAlertDialogOpen: Dispatcher<boolean>;
  uSMonitoringAlertsOnCamera: boolean;
  uSMonitoringAlerts: any;
  setAddUSMonitoringAlertDialogOpen: Dispatcher<boolean>;
}) => {
  return (
    <Box sx={{ width: "100%" }}>
      <ThemeProvider theme={theme}>
        <Tabs
          value={clickedTab}
          onChange={(event, tabValue) =>
            handleTabChangeEditCamera(
              tabValue,
              editMode,
              setClickedTab,
              setActiveTab,
              siteInfo,
              cameraGeneralInfo,
              setCameraGeneralInfo,
              setPatchObject,
              setGeneralInfoChangesMade,
              immixAlerts,
              setActiveChangeMade,
              sureviewAlerts,
              setAddImmixAlertDialogOpen,
              setAddSureviewAlertDialogOpen,
              streamsObject,
              activeProducts,
              recipientsConfiguredData,
              titlesArray,
              navigate,
              setBackdropLoadingScreenOn,
              setRecipientsConfiguredData,
              setTitlesArray,
              actionRulesData,
              setActionRulesData,
              actionRulesTitlesArray,
              setActionRulesTitlesArray,
              applicationsConfiguredData,
              timeTablesData,
              setTimeTablesData,
              iPNotifyData,
              setIPNotifyData,
              actionHandlerData,
              setActionHandlerData,
              axisFinished,
              setSendingImmixTest,
              setImmixTestSuccessDialogOpen,
              setImmixTestFailDialogOpen,
              cameraWebhookData,
              setCameraWebhooksPatchObject,
              setCameraWebhooksChangesMade,
              productsData,
              setProductsData,
              allTabsOpen,
              setAllTabsOpen,
              setBackupProductsData,
              sentinelAlerts,
              setAddSentinelAlertDialogOpen,
              boldAlerts,
              setAddBoldAlertDialogOpen,
              patriotAlerts,
              setAddPatriotAlertDialogOpen,
              uSMonitoringAlerts,
              setAddUSMonitoringAlertDialogOpen
            )
          }
          aria-label="tabs"
          color="primary"
        >
          <Tab
            value="one"
            label="General Info"
            sx={{ fontFamily: "Mulish", fontWeight: "Bold", color: "primary" }}
          />
          <Tab
            value="two"
            label="Connection"
            sx={{ fontFamily: "Mulish", fontWeight: "Bold", color: "primary" }}
          />
          {/* {Object.keys(streamsObject).length > 0 && ( */}
          <Tab
            value="three"
            label="Notifications"
            sx={{
              fontFamily: "Mulish",
              fontWeight: "Bold",
              color: "primary",
            }}
          />
          {/* )} */}
          {/* {Object.keys(streamsObject).length > 0 && ( */}
          <Tab
            value="four"
            label="Products"
            sx={{
              fontFamily: "Mulish",
              fontWeight: "Bold",
              color: "primary",
            }}
          />
          {/* )} */}

          {immixAlertsOnCamera && (
            <Tab
              value="five"
              label="Immix Alerts"
              sx={{
                fontFamily: "Mulish",
                fontWeight: "Bold",
                color: "primary",
              }}
            />
          )}
          {sureViewAlertsOnCamera && (
            <Tab
              value="six"
              label="Sureview Alerts"
              sx={{
                fontFamily: "Mulish",
                fontWeight: "Bold",
                color: "primary",
              }}
            />
          )}
          {axisCamera && (
            <Tab
              value="seven"
              label="Axis Settings"
              sx={{
                fontFamily: "Mulish",
                fontWeight: "Bold",
                color: "primary",
              }}
            />
          )}
          {mobotixCamera && (
            <Tab
              value="eight"
              label="Mobotix Settings"
              sx={{
                fontFamily: "Mulish",
                fontWeight: "Bold",
                color: "primary",
              }}
            />
          )}
          {(cameraWebhookData || siteInfo.webhooks) && (
            <Tab
              value="nine"
              label="Webhook"
              sx={{
                fontFamily: "Mulish",
                fontWeight: "Bold",
                color: "primary",
              }}
            />
          )}
          {sentinelAlertsOnCamera && (
            <Tab
              value="ten"
              label="Sentinel Alerts"
              sx={{
                fontFamily: "Mulish",
                fontWeight: "Bold",
                color: "primary",
              }}
            />
          )}
          {boldAlertsOnCamera && (
            <Tab
              value="eleven"
              label="Bold Alerts"
              sx={{
                fontFamily: "Mulish",
                fontWeight: "Bold",
                color: "primary",
              }}
            />
          )}
          {/* {patriotAlertsOnCamera && (
            <Tab
              value="twelve"
              label="Patriot Alerts"
              sx={{
                fontFamily: "Mulish",
                fontWeight: "Bold",
                color: "primary",
              }}
            />
          )}
          {uSMonitoringAlertsOnCamera && (
            <Tab
              value="thirteen"
              label="US Monitoring Alerts"
              sx={{
                fontFamily: "Mulish",
                fontWeight: "Bold",
                color: "primary",
              }}
            />
          )} */}
        </Tabs>
      </ThemeProvider>
    </Box>
  );
};
