import { createMonitoringAPICall } from "../../../utils/createMonitoringAPICall";
import { AxiosError, AxiosResponse } from "axios";
import { Dispatcher } from "../../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { NavigateFunction } from "react-router-dom";
import { addSelectedAndDetectionTypeOnRefresh } from "./addSelectedAndDetectionTypeOnRefresh";
import { universalAPIErrorHandler } from "../../../utils/universalAPIErrorHandler";
import { checkIfCurrentDate } from "./checkIfCurrentDate";
import { timeSlicer } from "../timeSlicer";

const isAlertsUrl = () => {
  const currentUrl = window.location.href;
  return currentUrl.includes("alerts");
};

export const getAlertsRefresh = (
  setAlertData: Dispatcher<any>,
  navigate: NavigateFunction,
  groupId: string,
  siteId: string,
  cameraId: string,
  startDate: string,
  endDate: string,
  startTime: Date,
  endTime: Date,
  filterType: string,
  setSelectedRow: any,
  setRefreshingData: Dispatcher<boolean>,
  setSelectedAlertCameraInfo: any,
  setIgnoreZonesSelectedAlert: any,
  setAlertsIndexOrder: any,
  refreshInterval: any,
  setRefreshInterval: any,
  pageLoadedNumber: number,
  setSeeMoreURL: any,
  setMP4URL: any,
  setVideoClipLoading: any,
  setSelectedButtonsFilled: any,
  setAlertsToDisplay: any,
  setCurrentName: any,
  setPage: any,
  setDateOutsideRange: any,
  setFetchingFilteredData: any,
  setPageLoading: any,
  setUserFilters: any,
  userFilters: any,
  cameraOptions: any,
  siteOptions: any,
  startTimeStamp: any,
  setRefreshingToken: Dispatcher<boolean>,
  refreshingToken: boolean
) => {
  const AxiosUI = createMonitoringAPICall(``);
  const retryFunction = () => {
    localStorage.tokenRefreshing === "false" &&
      getAlertsRefresh(
        setAlertData,
        navigate,
        groupId,
        siteId,
        cameraId,
        startDate,
        endDate,
        startTime,
        endTime,
        filterType,
        setSelectedRow,
        setRefreshingData,
        setSelectedAlertCameraInfo,
        setIgnoreZonesSelectedAlert,
        setAlertsIndexOrder,
        refreshInterval,
        setRefreshInterval,
        pageLoadedNumber,
        setSeeMoreURL,
        setMP4URL,
        setVideoClipLoading,
        setSelectedButtonsFilled,
        setAlertsToDisplay,
        setCurrentName,
        setPage,
        setDateOutsideRange,
        setFetchingFilteredData,
        setPageLoading,
        setUserFilters,
        userFilters,
        cameraOptions,
        siteOptions,
        startTimeStamp,
        setRefreshingToken,
        refreshingToken
      );
  };

  let searchingPastDates = false;

  let searchParamsString = ``;

  if (startDate) {
    searchParamsString += `&start=${startDate}`;
  }

  if (endDate) {
    searchParamsString += `&end=${endDate}`;
    searchingPastDates = checkIfCurrentDate(endDate);
  }

  if (groupId) {
    if (groupId !== "undefined") {
      searchParamsString += `&group_id=${groupId}`;
    }
  }

  if (siteId) {
    if (siteId !== "undefined") {
      searchParamsString += `&customer_id=${siteId}`;
    }
  }

  if (cameraId) {
    if (cameraId !== "undefined") {
      searchParamsString += `&camera_id=${cameraId}`;
    }
  }

  if (startDate.toString() === endDate.toString() && startTime !== null) {
    searchParamsString += `&start_time=${timeSlicer(startTime)}`;
  }

  if (startDate.toString() === endDate.toString() && endTime !== null) {
    searchParamsString += `&end_time=${timeSlicer(endTime)}`;
  }

  localStorage.setItem(
    "queryString",
    JSON.stringify(`${filterType}${searchParamsString}`)
  );

  if (
    Number(pageLoadedNumber) ===
      Number(JSON.parse(localStorage.pageLoadedNumber)) &&
    isAlertsUrl() &&
    !searchingPastDates
  ) {
    AxiosUI.get(`alert/?window_filter=${filterType}${searchParamsString}`).then(
      (response: AxiosResponse) => {
        let previousTimeStamps = startTimeStamp;
        if (response.data.records[0]) {
          previousTimeStamps =
            response.data.records[0].approx_capture_timestamp;
        }
        if (previousTimeStamps !== startTimeStamp) {
          setRefreshingData(true);
          const newData = response.data;
          if (newData.records[0]) {
            setAlertsToDisplay(true);
            if (
              JSON.parse(localStorage.queryString) ===
              `${filterType}${searchParamsString}`
            ) {
              setAlertData(
                addSelectedAndDetectionTypeOnRefresh(
                  newData.records,
                  setAlertsIndexOrder,
                  setSeeMoreURL,
                  setSelectedRow,
                  setSelectedAlertCameraInfo,
                  setIgnoreZonesSelectedAlert,
                  navigate,
                  setMP4URL,
                  setVideoClipLoading,
                  setSelectedButtonsFilled,
                  newData,
                  setAlertData,
                  refreshingToken
                )
              );
            }
            setRefreshingData(false);
          } else {
            setAlertData([]);
            setAlertsToDisplay(false);
            setRefreshingData(false);
          }
        }
        if (localStorage.tokenRefreshing === "false") {
          setTimeout(() => {
            getAlertsRefresh(
              setAlertData,
              navigate,
              groupId,
              siteId,
              cameraId,
              startDate,
              endDate,
              startTime,
              endTime,
              filterType,
              setSelectedRow,
              setRefreshingData,
              setSelectedAlertCameraInfo,
              setIgnoreZonesSelectedAlert,
              setAlertsIndexOrder,
              refreshInterval,
              setRefreshInterval,
              pageLoadedNumber,
              setSeeMoreURL,
              setMP4URL,
              setVideoClipLoading,
              setSelectedButtonsFilled,
              setAlertsToDisplay,
              setCurrentName,
              setPage,
              setDateOutsideRange,
              setFetchingFilteredData,
              setPageLoading,
              setUserFilters,
              userFilters,
              cameraOptions,
              siteOptions,
              previousTimeStamps,
              setRefreshingToken,
              refreshingToken
            );
          }, refreshInterval);
        }
      },
      (reason: AxiosError) => {
        setRefreshingToken(true);
        universalAPIErrorHandler(
          reason,
          navigate,
          () => {
            retryFunction();
            setRefreshingToken(false);
          },
          () => {
            setRefreshingToken(false);
            setRefreshingData(false);
            if (localStorage.tokenRefreshing === "false") {
              setTimeout(() => {
                getAlertsRefresh(
                  setAlertData,
                  navigate,
                  groupId,
                  siteId,
                  cameraId,
                  startDate,
                  endDate,
                  startTime,
                  endTime,
                  filterType,
                  setSelectedRow,
                  setRefreshingData,
                  setSelectedAlertCameraInfo,
                  setIgnoreZonesSelectedAlert,
                  setAlertsIndexOrder,
                  refreshInterval,
                  setRefreshInterval,
                  pageLoadedNumber,
                  setSeeMoreURL,
                  setMP4URL,
                  setVideoClipLoading,
                  setSelectedButtonsFilled,
                  setAlertsToDisplay,
                  setCurrentName,
                  setPage,
                  setDateOutsideRange,
                  setFetchingFilteredData,
                  setPageLoading,
                  setUserFilters,
                  userFilters,
                  cameraOptions,
                  siteOptions,
                  startTimeStamp,
                  setRefreshingToken,
                  refreshingToken
                );
              }, refreshInterval);
            }
          }
        );
      }
    );
  }
};
