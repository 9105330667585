import React from "react";

export const resetState = (
  setCameraDataLoaded: any,
  setCameraObject: any,
  setSiteInfo: any,
  setClickedTab: any,
  setEditMode: any,
  setAddImmixAlertDialogOpen: any,
  setAddSureviewAlertDialogOpen: any,
  setCameraGeneralInfo: any,
  setCameraGeneralInfoBackup: any,
  setPatchObject: any,
  setGeneralInfoChangesMade: any,
  setActiveChangeMade: any,
  setAxisCamera: any,
  setMobotixCamera: any,
  setActiveTab: any,
  setBackdropLoadingScreenOn: any,
  setImmixAlertsOnCamera: any,
  setImmixAlerts: any,
  setSureViewAlertsOnCamera: any,
  setSureviewAlerts: any,
  setIgnoreZonesBreadCrumbs: any,
  setEditCameraAlerts: any,
  setRecipientsConfiguredData: any,
  setTitlesArray: any,
  setActionRulesData: any,
  setActionRulesTitlesArray: any,
  setApplicationsConfiguredData: any,
  setTimeTablesData: any,
  setIPNotifyData: any,
  setActionHandlerData: any,
  setMobotixFinished: any,
  setAxisFinished: any,
  setStreamsObject: any,
  setActiveProducts: any,
  setSendingImmixTest: any,
  setImmixTestSuccessDialogOpen: any,
  setImmixTestFailDialogOpen: any,
  setv1RedirectDialogOpen: any,
  setSrcImages: any,
  setCameraInfoRow: any,
  setCameraWebhookData: any,
  setCameraWebhooksPatchObject: any,
  setCameraWebhooksChangesMade: any,
  setProductsData: any,
  setBackupProductsData: any,
  setAllTabsOpen: any,
  setAllCamerasData: any,
  setCameraIndex: any,
  setSentinelAlertsOnCamera: any,
  setSentinelAlerts: any,
  setBoldAlerts: any,
  setBoldAlertsOnCamera: any,
  setPatriotAlerts: any,
  setPatriotAlertsOnCamera: any,
  setUSMonitoringAlerts: any,
  setUSMonitoringAlertsOnCamera: any
) => {
  setCameraDataLoaded(false);
  setCameraObject({});
  setSiteInfo({});
  setClickedTab("one");
  setEditMode(false);
  setAddImmixAlertDialogOpen(false);
  setAddSureviewAlertDialogOpen(false);
  setCameraGeneralInfo({});
  setCameraGeneralInfoBackup({});
  setPatchObject({});
  setGeneralInfoChangesMade(false);
  setActiveChangeMade(false);
  setAxisCamera(false);
  setMobotixCamera(false);
  setActiveTab(<></>);
  setBackdropLoadingScreenOn(true);
  setImmixAlertsOnCamera(false);
  setImmixAlerts([]);
  setSureViewAlertsOnCamera(false);
  setSureviewAlerts([]);
  setSentinelAlertsOnCamera(false);
  setSentinelAlerts([]);
  setBoldAlerts([]);
  setBoldAlertsOnCamera(false);
  setPatriotAlerts([]);
  setPatriotAlertsOnCamera(false);
  setUSMonitoringAlerts([]);
  setUSMonitoringAlertsOnCamera(false);
  setIgnoreZonesBreadCrumbs(<></>);
  setEditCameraAlerts({
    triggerMotionSuccess: false,
    triggerMotionFail: false,
  });
  setRecipientsConfiguredData([]);
  setTitlesArray([
    "Name",
    "Recipient Type",
    "Host",
    "Port",
    "Email From",
    "Email To",
  ]);
  setActionRulesData([]);
  setActionRulesTitlesArray([
    "Name",
    "Host",
    "Port",
    "Email From",
    "Email To",
    "Message",
    "Enabled",
    "Profile",
  ]);
  setApplicationsConfiguredData([]);
  setTimeTablesData([]);
  setIPNotifyData([]);
  setActionHandlerData([]);
  setMobotixFinished({
    timeTable: false,
    iPNotify: false,
    actionHandler: false,
  });
  setAxisFinished({
    recipient: false,
    actionRules: false,
    application: false,
  });
  setStreamsObject({});
  setActiveProducts([]);
  setSendingImmixTest(false);
  setImmixTestSuccessDialogOpen(false);
  setImmixTestFailDialogOpen(false);
  setv1RedirectDialogOpen(false);
  setSrcImages({});
  setCameraInfoRow("");
  setCameraWebhookData("");
  setCameraWebhooksPatchObject({});
  setCameraWebhooksChangesMade(false);
  setProductsData([
    {
      name: "Intruder",
      label_name: "intruder",
      open: false,
      metrics: {},
    },
    {
      name: "Intruder Plus",
      label_name: "intruderPlus",
      open: false,
      metrics: {},
    },
    { name: "Gun", label_name: "gun", open: false, metrics: {} },
    {
      name: "Crowd",
      label_name: "crowd",
      open: false,
      metrics: {},
    },
    {
      name: "Loitering",
      label_name: "loitering",
      open: false,
      metrics: {},
    },
    {
      name: "Fall Person",
      label_name: "fallPerson",
      open: false,
      metrics: {},
    },
    {
      name: "No Hard Hat",
      label_name: "noHardHat",
      open: false,
      metrics: {},
    },
    { name: "Mask", label_name: "mask", open: false, metrics: {} },
    {
      name: "Social Distancing",
      label_name: "socialDistancing",
      open: false,
      metrics: {},
    },
    {
      name: "Left Object",
      label_name: "leftObject",
      open: false,
      metrics: {},
    },
    {
      name: "Package",
      label_name: "package",
      open: false,
      metrics: {},
    },
    { name: "Fire", label_name: "fire", open: false, metrics: {} },
    {
      name: "Vehicle Logo ID",
      label_name: "vehicleLogoId",
      open: false,
      metrics: {},
    },
    {
      name: "Non-UPS",
      label_name: "nonUps",
      open: false,
      metrics: {},
    },
  ]);
  setBackupProductsData([]);
  setAllTabsOpen(false);
  setAllCamerasData([]);
  setCameraIndex(0);
};
