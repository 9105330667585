import { createApi } from "../../../utils/createApi";
import { AxiosError, AxiosResponse } from "axios";
import { universalAPIErrorHandler } from "../../../utils/universalAPIErrorHandler";
import {
  getNumbersAfterEditCamera,
  getNumbersAfterSites,
} from "../../EditCamera/EditCamera";

const replaceNegativeWithZero = (data: number[][][]): number[][][] => {
  if (!data || data.length === 0) {
    return data;
  }

  for (let i = 0; i < data.length; i++) {
    for (let j = 0; j < data[i].length; j++) {
      for (let k = 0; k < data[i][j].length; k++) {
        if (data[i][j][k] < 0) {
          data[i][j][k] = 0;
        }
      }
    }
  }
  return data;
};

export const handleSubmitIgnoreZones = async (
  submitObject: any,
  navigate: any,
  setPageLoading: any
) => {
  const retryFunction = () => {
    handleSubmitIgnoreZones(submitObject, navigate, setPageLoading);
  };
  let submitIZfail = false;
  let deployFail = false;
  const AxiosUI = createApi(``);
  setPageLoading(true);
  submitObject = submitObject.map((obj: any) => {
    return {
      ...obj,
      coordinates: replaceNegativeWithZero(obj.coordinates),
    };
  });

  await AxiosUI.post(
    `/camera/${getNumbersAfterEditCamera(location.href)}/ignore_zones/`,
    submitObject
  ).then(
    () => {
      submitIZfail = false;
      localStorage.setItem("rebootSite", "true");
    },
    (reason: AxiosError) => {
      submitIZfail = true;
      universalAPIErrorHandler(reason, navigate, retryFunction, () => {
        alert("Error submitting Ignore Zone changes, please try again");
      });
    }
  );
  !submitIZfail &&
    (await AxiosUI.post(
      `customer/${getNumbersAfterSites(location.href)}/action/deploy_next/`
    ).then(
      () => {
        localStorage.setItem("syncNeeded", "false");
        deployFail = false;
      },
      (reason: AxiosError) => {
        deployFail = true;
        universalAPIErrorHandler(reason, navigate, retryFunction, () => {});
      }
    ));
  !submitIZfail &&
    !deployFail &&
    location.href.includes("editignorezones") &&
    (await AxiosUI.get(
      `camera/site/?customer__id=${getNumbersAfterSites(location.href)}`
    ).then(
      (response: AxiosResponse) => {
        localStorage.setItem("siteCameras", JSON.stringify(response.data));
        navigate(
          `/sites/${Number(
            getNumbersAfterSites(location.href)
          )}/editcamera/${Number(getNumbersAfterEditCamera(location.href))}`
        );
        // navigate(`/sites/aboutsite/${getNumbersAfterSites(location.href)}`);
      },
      (reason: AxiosError) => {
        universalAPIErrorHandler(reason, navigate, retryFunction, () => {});
      }
    ));
};
