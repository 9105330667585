/* eslint-disable jsx-a11y/media-has-caption */
import React from "react";
import { NavBar } from "../../common/NavBar";
import { AlertsTable } from "./AlertsTable";
import { AlertData } from "./AlertsUtils/alertsInterfaces";
import { AlertsIdentifierBox } from "./AlertsIdentifierBox";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getAlerts } from "./AlertsUtils/getAlerts";
import BackdropLoadingScreen from "../../common/BackdropLoadingScreen";
import { OrangeTextButton } from "../../common/OrangeTextButton";
import useWindowDimensions from "../../common/useWindowDimensions";
import { NotesDialog } from "./NotesDialog";
import { Alert, Dialog, DialogContent, Tooltip } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";
import RefreshIcon from "@mui/icons-material/Refresh";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { handleForwardButtonClick } from "./AlertsUtils/handleForwardButtonClick";
import { handleBackButtonClick } from "./AlertsUtils/handleBackButtonClick";
import { createSiteHyperlink } from "./AlertsUtils/createSiteHyperlink";
import { createCameraHyperlink } from "./AlertsUtils/createCameraHyperlink";
import ErrorBoundary from "../../../ErrorBoundary";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { handleRowSelected } from "./AlertsUtils/handleRowSelected";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { ActiveIgnoreZonesWindow } from "./ActiveIgnoreZonesWindow";
import CameraInfoTable from "./CameraInfoTable";
import { saveAs } from "file-saver";
import { updateSearchParamValues } from "../../utils/updateSearchParamValues";
import { getSitesByParentGroupAlerts } from "./AlertsUtils/getSitesByParentGroupAlerts";
import { getCamerasBySiteAlerts } from "./AlertsUtils/getCamerasBySiteAlerts";
import { getParentAccounts } from "../../utils/getParentAccounts";
import { FiltersAlerts } from "./FiltersAlerts";
import { convertHHMMTime } from "./AlertsUtils/convertHHMMTime";
import { handleCreateFilterTypeName } from "./AlertsUtils/handleCreateFilterTypeName";
import { getIgnoreZoneDialogInfo } from "./AlertsUtils/getIgnoreZoneDialogInfo";
import { getAlertsDownloadURL } from "./AlertsUtils/getAlertsDownloadURL";
import { yyyymmddGenerator } from "../Analytics/AnalyticsUtils/yyyymmddGenerator";
declare module "@mui/material/styles" {
  interface Palette {
    neutral: Palette["primary"];
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#FF8400",
    },
  },
});

const AlertsMirror = ({
  navBarCollapsed,
  setNavBarCollapsed,
}: {
  navBarCollapsed: boolean;
  setNavBarCollapsed: Dispatcher<boolean>;
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const group = searchParams.get("group");
  const site = searchParams.get("site");
  const camera = searchParams.get("camera");
  const start_date = searchParams.get("start_date");
  const end_date = searchParams.get("end_date");
  const start_time = searchParams.get("start_time");
  const end_time = searchParams.get("end_time");
  const filter_type = searchParams.get("filter_type");
  const detection_type = searchParams.get("detection_type");

  const [userFilters, setUserFilters] = React.useState<any>({
    parent_group: group,
    site: site,
    camera: camera,
    start_date: start_date ? new Date(start_date) : null,
    end_date: end_date ? new Date(end_date) : null,
    start_time: start_time ? convertHHMMTime(start_time) : null,
    end_time: end_time ? convertHHMMTime(end_time) : null,
    filter_type: filter_type ? filter_type : "UNCLAIMED",
  });

  React.useEffect(() => {
    if (searchParams.size > 0) {
      updateSearchParamValues(
        userFilters,
        searchParams,
        setSearchParams,
        detectionType
      );
    }
  }, []);

  const [alertData, setAlertData] = React.useState<AlertData[]>([]);

  const navigate = useNavigate();

  const [pageLoading, setPageLoading] = React.useState(false);

  const [selected, setSelected] = React.useState<string[]>([]);

  const [selectedRow, setSelectedRow] = React.useState<any>({});

  const [page, setPage] = React.useState(0);

  const [selectdButtonsFilled, setSelectedButtonsFilled] = React.useState({
    true_threat: true,
    true_non_threat: true,
    false_positive: true,
  });

  React.useEffect(() => {
    getParentAccounts(
      setParentAccountsLoading,
      setParentAccounts,
      setParentIds,
      setParentNames,
      navigate
    );

    if (group) {
      getSitesByParentGroupAlerts(
        group,
        navigate,
        setSiteOptions,
        setLoadingSites
      );
    }
    if (site) {
      getCamerasBySiteAlerts(
        [site],
        navigate,
        setCameraOptions,
        setLoadingCameras
      );
    }
  }, []);

  const [refreshingToken, setRefreshingToken] = React.useState(false);

  const [buttonClicked, setButtonClicked] = React.useState(false);

  const [fetchingFilteredData, setFetchingFilteredData] = React.useState(false);

  const [seeMoreURL, setSeeMoreURL] = React.useState<any>("");

  const [mP4URL, setMP4URL] = React.useState<any>("");

  const [thumbnailVideos, setThumbnailVideos] = React.useState<any>({});

  const [dateOutsideRange, setDateOutsideRange] = React.useState(false);

  const [ignoreZoneDialogOpen, setIgnoreZoneDialogOpen] = React.useState(false);

  const [siteOptions, setSiteOptions] = React.useState<any[]>([]);

  const [cameraOptions, setCameraOptions] = React.useState<any[]>([]);

  const [siteDropDownValue, setSiteDropDownValue] = React.useState<string>(
    site ? site : ""
  );

  const [cameraDropDownValue, setCameraDropDownValue] = React.useState<string>(
    camera ? camera : ""
  );

  const [notesDialogOpen, setNotesDialogOpen] = React.useState(false);

  const [noteText, setNoteText] = React.useState("");

  const [videoClipLoading, setVideoClipLoading] = React.useState(true);

  const [alertsToDisplay, setAlertsToDisplay] = React.useState(false);

  const [refreshingData, setRefreshingData] = React.useState(false);

  const { width } = useWindowDimensions();

  const [alertIndex, setAlertIndex] = React.useState(0);

  const [timeFiltersDisabled, setTimeFiltersDisabled] = React.useState(false);

  const [loadingSites, setLoadingSites] = React.useState(group ? true : false);

  const [loadingCameras, setLoadingCameras] = React.useState(
    site ? true : false
  );

  const [selectedAlertCameraInfo, setSelectedAlertCameraInfo] = React.useState(
    {}
  );

  const [ignoreZonesSelectedAlert, setIgnoreZonesSelectedAlert] =
    React.useState([]);

  const [alertsIndexOrder, setAlertsIndexOrder] = React.useState([]);

  const [refreshInterval, setRefreshInterval] = React.useState(
    JSON.parse(localStorage.refresh_interval)
  );

  const [currentName, setCurrentName] = React.useState(
    filter_type ? handleCreateFilterTypeName(filter_type) : "Unresolved Alerts"
  );

  const [pageLoadedNumber, setPageLoadedNumber] = React.useState(0);

  const [parentAccounts, setParentAccounts] = React.useState([]);

  const [parentAccountsLoading, setParentAccountsLoading] =
    React.useState(true);

  const [parentIds, setParentIds] = React.useState(new Map());

  const [parentNames, setParentNames] = React.useState(new Map());

  const [warningIssued, setWarningIssued] = React.useState(false);

  const [alertLabels, setAlertLabels] = React.useState<string[]>(["All"]);

  const [detectionType, setDetectionType] = React.useState(
    detection_type ? detection_type : "All"
  );

  const [periodHours, setPeriodHours] = React.useState(2);

  const [queryLimitHit, setQueryLimitHit] = React.useState(false);

  const [filtersApplied, setFiltersApplied] = React.useState(false);

  const [loadingIgnoreZonesInfo, setLoadingIgnoreZonesInfo] =
    React.useState(false);

  const [alertsDownloadURL, setAlertsDownloadURL] = React.useState("");

  const refreshText = (
    <>
      <RefreshIcon fontSize="small" />
      &nbsp;Try Refresh
    </>
  );

  const cameraIds = new Map();
  cameraOptions.forEach((object: any) => {
    cameraIds.set(object.camera_name, object.id);
  });

  const siteIds = new Map();
  siteOptions.forEach((object: any) => {
    siteIds.set(object.name, object.id);
  });

  const ref = React.useRef<HTMLDivElement>(null);

  const setScrollTop = (event: any) => {
    localStorage.setItem("aletsScrollTop", event.target.scrollTop);
  };

  React.useEffect(() => {
    if (ref) {
      if (ref.current !== null) {
        ref.current.scrollTop = localStorage.aletsScrollTop;
      }
    }
  }, [alertData]);

  const scrollToTop = () => {
    if (ref) {
      if (ref.current !== null) {
        ref.current.scrollTop = 0;
      }
    }
  };

  React.useEffect(() => {
    if (searchParams.size > 0) {
      setPageLoadedNumber(localStorage.pageLoadedNumber);
      setPageLoading(true);
      getAlerts(
        setAlertData,
        navigate,
        setSelectedRow,
        setPageLoading,
        setSeeMoreURL,
        setMP4URL,
        userFilters,
        setThumbnailVideos,
        setDateOutsideRange,
        setVideoClipLoading,
        setAlertsToDisplay,
        setSelectedAlertCameraInfo,
        setIgnoreZonesSelectedAlert,
        setAlertsIndexOrder,
        setRefreshInterval,
        setFetchingFilteredData,
        setCurrentName,
        setPage,
        setSelectedButtonsFilled,
        setUserFilters,
        cameraOptions,
        siteOptions,
        setRefreshingData,
        localStorage.pageLoadedNumber,
        refreshInterval,
        setParentAccountsLoading,
        setParentAccounts,
        setParentIds,
        setParentNames,
        setRefreshingToken,
        refreshingToken,
        setAlertLabels,
        setPeriodHours,
        setFiltersApplied
      );
      getAlertsDownloadURL(
        setAlertsDownloadURL,
        userFilters.parent_group,
        userFilters.site,
        userFilters.camera,
        yyyymmddGenerator(userFilters.start_date),
        yyyymmddGenerator(userFilters.end_date),
        userFilters.start_time,
        userFilters.end_time
      );
    }
  }, []);

  // React.useEffect(() => {
  //   localStorage.setItem("pageLoadedNumber", JSON.stringify(0));
  // }, [alertData]);

  return (
    <div>
      {selectedRow && (
        <NotesDialog
          notesDialogOpen={notesDialogOpen}
          setNotesDialogOpen={setNotesDialogOpen}
          selectedRow={selectedRow}
          noteText={noteText}
          setNoteText={setNoteText}
          alertData={alertData}
          setAlertData={setAlertData}
          setSelectedRow={setSelectedRow}
          navigate={navigate}
        />
      )}
      <Dialog open={ignoreZoneDialogOpen}>
        <IconButton
          aria-label="close"
          onClick={() => {
            setIgnoreZoneDialogOpen(false);
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <ActiveIgnoreZonesWindow
            selectedAlertCameraInfo={selectedAlertCameraInfo}
            ignoreZonesSelectedAlert={ignoreZonesSelectedAlert}
            loadingIgnoreZonesInfo={loadingIgnoreZonesInfo}
          />
        </DialogContent>
      </Dialog>
      <BackdropLoadingScreen openState={fetchingFilteredData} />
      <BackdropLoadingScreen openState={buttonClicked} />
      <BackdropLoadingScreen openState={pageLoading} />
      {dateOutsideRange && (
        <Alert
          severity="warning"
          onClose={() => {
            setDateOutsideRange(false);
          }}
        >
          Alerts with dates outside of selected range are displayed.
        </Alert>
      )}
      {queryLimitHit && (
        <Alert
          severity="warning"
          onClose={() => {
            setQueryLimitHit(false);
          }}
        >
          <p className="font-mulish font-bold text-[16px]">
            The selected parent group is too large. Only the 100 most recent
            alerts are displayed. Please select a specific site to view the
            accurate number of alerts.
          </p>
        </Alert>
      )}
      <div
        className="bg-actuate-grey absolute w-screen h-screen overflow-scroll"
        onScroll={setScrollTop}
        ref={ref}
      >
        <div className="bg-white absolute w-screen h-[90px]"></div>
        <div className="relative">
          <div className="font-mulish relative left-[170px] top-[15px] max-w-[800px] max-h-full">
            <FiltersAlerts
              navBarCollapsed={navBarCollapsed}
              userFilters={userFilters}
              setUserFilters={setUserFilters}
              navigate={navigate}
              setSiteOptions={setSiteOptions}
              setSiteDropDownValue={setSiteDropDownValue}
              setCameraOptions={setCameraOptions}
              setCameraDropDownValue={setCameraDropDownValue}
              setLoadingSites={setLoadingSites}
              setRefreshInterval={setRefreshInterval}
              parentAccounts={parentAccounts}
              parentIds={parentIds}
              parentAccountsLoading={parentAccountsLoading}
              siteOptions={siteOptions}
              siteDropDownValue={siteDropDownValue}
              loadingSites={loadingSites}
              setLoadingCameras={setLoadingCameras}
              cameraOptions={cameraOptions}
              cameraDropDownValue={cameraDropDownValue}
              loadingCameras={loadingCameras}
              setFetchingFilteredData={setFetchingFilteredData}
              setAlertData={setAlertData}
              setSelectedRow={setSelectedRow}
              setSeeMoreURL={setSeeMoreURL}
              setMP4URL={setMP4URL}
              setSelectedButtonsFilled={setSelectedButtonsFilled}
              setThumbnailVideos={setThumbnailVideos}
              setDateOutsideRange={setDateOutsideRange}
              setPage={setPage}
              setVideoClipLoading={setVideoClipLoading}
              setAlertsToDisplay={setAlertsToDisplay}
              setPageLoading={setPageLoading}
              setTimeFiltersDisabled={setTimeFiltersDisabled}
              warningIssued={warningIssued}
              setWarningIssued={setWarningIssued}
              timeFiltersDisabled={timeFiltersDisabled}
              setFiltersApplied={setFiltersApplied}
              pageLoadedNumber={pageLoadedNumber}
              setPageLoadedNumber={setPageLoadedNumber}
              searchParams={searchParams}
              setSearchParams={setSearchParams}
              detectionType={detectionType}
              setSelectedAlertCameraInfo={setSelectedAlertCameraInfo}
              setIgnoreZonesSelectedAlert={setIgnoreZonesSelectedAlert}
              setAlertsIndexOrder={setAlertsIndexOrder}
              setCurrentName={setCurrentName}
              setRefreshingData={setRefreshingData}
              setRefreshingToken={setRefreshingToken}
              refreshingToken={refreshingToken}
              setAlertLabels={setAlertLabels}
              setPeriodHours={setPeriodHours}
              setQueryLimitHit={setQueryLimitHit}
              setAlertsDownloadURL={setAlertsDownloadURL}
            />
          </div>
          <div className="absolute top-[90px]">
            <svg width={width}>
              <line
                x1="0"
                y1="0"
                x2="100%"
                y2="0"
                style={{ stroke: `#BDBDBD`, strokeWidth: 2 }}
              />
            </svg>
          </div>
          {!alertsToDisplay &&
            filtersApplied &&
            !pageLoading &&
            (userFilters.start_date || userFilters.end_date) && (
              <div className="relative top-[100px] text-center pb-[20px]">
                <p className="font-mulish text-[22px]">No Alerts to Display.</p>
              </div>
            )}
          {!alertsToDisplay &&
            filtersApplied &&
            !pageLoading &&
            !(userFilters.start_date || userFilters.end_date) && (
              <div className="relative top-[100px] text-center pb-[20px]">
                <p className="font-mulish text-[17px]">
                  {`No Alerts to Display. Unless date filters are added, only the most recent alerts from the past ${periodHours} hour(s) will appear.`}
                </p>
              </div>
            )}
          {seeMoreURL && alertsToDisplay && (
            <div className="relative top-[90px] left-[1300px] w-[100px]">
              <OrangeTextButton
                onClick={() => window.open(`${seeMoreURL}`, "_blank")}
                disabled={false}
                text="SEE ALERT"
              />
            </div>
          )}

          {seeMoreURL && alertsToDisplay && selectedRow && (
            <div>
              <p
                className={
                  !navBarCollapsed
                    ? "relative left-[180px] top-[62px] w-[1000px] text-actuate-blue font-mulish text-[18px]"
                    : "relative left-[100px] top-[62px] w-[1000px] text-actuate-blue font-mulish text-[18px]"
                }
              >
                {selectedRow.display_name
                  ? createSiteHyperlink(
                      selectedRow.display_name,
                      selectedRow.customer_pk
                    )
                  : createSiteHyperlink(
                      selectedRow.customer_name,
                      selectedRow.customer_pk
                    )}{" "}
                |{" "}
                {createCameraHyperlink(
                  selectedRow.camera_name,
                  selectedRow.customer_pk,
                  selectedRow.camera_pk
                )}
              </p>

              <div className="relative left-[800px] top-[62px] w-[680px] h-[325px]">
                <div className="relative h-[440px] w-[680px]">
                  <div>
                    {videoClipLoading && (
                      <div className="text-center">
                        <ThemeProvider theme={theme}>
                          <div>
                            <CircularProgress color="primary" />
                          </div>
                        </ThemeProvider>
                        <br></br>
                        <p className="font-mulish text-[20px]">
                          Video Loading...
                        </p>
                      </div>
                    )}
                    {!videoClipLoading && !mP4URL && (
                      <div className="text-[20px] font-mulish text-center">
                        <p>Video clip not available.</p>
                        <p>
                          If recent, video will be viewable when alarm closes.
                        </p>
                        <OrangeTextButton
                          onClick={() => {
                            handleRowSelected(
                              JSON.parse(localStorage.selectedRowAlerts),
                              alertData,
                              setAlertData,
                              setSelectedButtonsFilled,
                              setSeeMoreURL,
                              navigate,
                              setMP4URL,
                              setVideoClipLoading,
                              setSelectedRow,
                              setSelectedAlertCameraInfo,
                              setIgnoreZonesSelectedAlert,
                              refreshingToken
                            );
                            // getAlertMP4(
                            //   selectedRow,
                            //   navigate,
                            //   setMP4URL,
                            //   setVideoClipLoading
                            // );
                          }}
                          text={refreshText}
                          disabled={false}
                        />
                      </div>
                    )}
                    {mP4URL && !videoClipLoading && (
                      <div className="flex items-center justify-between w-[680px]">
                        <div className="flex-none" id="arrow_back">
                          <OrangeTextButton
                            onClick={() => {
                              setRefreshingData(true);
                              handleBackButtonClick(
                                selectedRow,
                                setAlertIndex,
                                setSelectedRow,
                                alertData,
                                navigate,
                                setMP4URL,
                                setVideoClipLoading,
                                setSelectedButtonsFilled,
                                setSeeMoreURL,
                                setAlertData,
                                setRefreshingData,
                                setSelectedAlertCameraInfo,
                                setIgnoreZonesSelectedAlert,
                                alertsIndexOrder,
                                detectionType
                              );
                            }}
                            text={
                              <ArrowBackIosIcon
                                fontSize="large"
                                id="backButton"
                              />
                            }
                            disabled={selectedRow.index === alertsIndexOrder[0]}
                          />
                        </div>
                        <div className="flex-auto" id="video">
                          <video width="540" controls autoPlay loop>
                            <source src={mP4URL} type="video/mp4" />
                          </video>
                          <div>
                            <OrangeTextButton
                              onClick={() => saveAs(mP4URL, "video.mp4")}
                              text="Download Video"
                              disabled={false}
                            />
                          </div>
                        </div>
                        <div className="flex-none" id="arrow_forward">
                          <OrangeTextButton
                            onClick={() => {
                              setRefreshingData(true);
                              handleForwardButtonClick(
                                selectedRow,
                                setAlertIndex,
                                setSelectedRow,
                                alertData,
                                navigate,
                                setMP4URL,
                                setVideoClipLoading,
                                setSelectedButtonsFilled,
                                setSeeMoreURL,
                                setAlertData,
                                setRefreshingData,
                                setSelectedAlertCameraInfo,
                                setIgnoreZonesSelectedAlert,
                                alertsIndexOrder,
                                detectionType
                              );
                            }}
                            text={
                              <ArrowForwardIosIcon
                                fontSize="large"
                                id="forwardButton"
                              />
                            }
                            disabled={
                              selectedRow.index ===
                              alertsIndexOrder[alertsIndexOrder.length - 1]
                            }
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="pt-[10px] pl-[30px]">
                    {mP4URL && !videoClipLoading && (
                      <ErrorBoundary>
                        <AlertsIdentifierBox
                          selectedRow={selectedRow}
                          alertData={alertData}
                          setAlertData={setAlertData}
                          setSelectedRow={setSelectedRow}
                          selectdButtonsFilled={selectdButtonsFilled}
                          setSelectedButtonsFilled={setSelectedButtonsFilled}
                          navigate={navigate}
                          setPageLoading={setPageLoading}
                          setButtonClicked={setButtonClicked}
                        />
                      </ErrorBoundary>
                    )}
                  </div>
                  <div>
                    {alertsToDisplay &&
                      seeMoreURL &&
                      !pageLoading &&
                      mP4URL &&
                      !videoClipLoading && (
                        <div className="pl-[30px] pt-[3px]">
                          <Tooltip
                            title={
                              <ActiveIgnoreZonesWindow
                                selectedAlertCameraInfo={
                                  selectedAlertCameraInfo
                                }
                                ignoreZonesSelectedAlert={
                                  ignoreZonesSelectedAlert
                                }
                                loadingIgnoreZonesInfo={loadingIgnoreZonesInfo}
                              />
                            }
                            placement="top-start"
                          >
                            <OrangeTextButton
                              text="View Active Ignore Zones"
                              disabled={false}
                              onClick={() => {
                                setLoadingIgnoreZonesInfo(true);
                                getIgnoreZoneDialogInfo(
                                  selectedRow,
                                  setSelectedAlertCameraInfo,
                                  setIgnoreZonesSelectedAlert,
                                  setLoadingIgnoreZonesInfo,
                                  navigate
                                );
                                setIgnoreZoneDialogOpen(true);
                              }}
                            />
                          </Tooltip>
                        </div>
                      )}
                  </div>
                  <div className="pl-[25px]">
                    {alertsToDisplay &&
                      seeMoreURL &&
                      !pageLoading &&
                      mP4URL &&
                      !videoClipLoading && (
                        <CameraInfoTable
                          selectedRow={selectedRow}
                          setNoteText={setNoteText}
                          setNotesDialogOpen={setNotesDialogOpen}
                        />
                      )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        {!pageLoading && filtersApplied && (
          <div>
            <div
              className={
                !navBarCollapsed
                  ? "absolute top-[138px] left-[150px] right-[1px] w-[655px]"
                  : "absolute top-[138px] left-[80px] right-[1px] w-[725px]"
              }
            >
              {!fetchingFilteredData && !refreshingData && (
                <div>
                  <AlertsTable
                    selected={selected}
                    alertData={alertData}
                    setAlertData={setAlertData}
                    setSelected={setSelected}
                    setSelectedRow={setSelectedRow}
                    setSelectedButtonsFilled={setSelectedButtonsFilled}
                    setSeeMoreURL={setSeeMoreURL}
                    navigate={navigate}
                    setMP4URL={setMP4URL}
                    page={page}
                    setPage={setPage}
                    userFilters={userFilters}
                    setFetchingFilteredData={setFetchingFilteredData}
                    setUserFilters={setUserFilters}
                    setThumbnailVideos={setThumbnailVideos}
                    setDateOutsideRange={setDateOutsideRange}
                    setVideoClipLoading={setVideoClipLoading}
                    setAlertsToDisplay={setAlertsToDisplay}
                    setPageLoading={setPageLoading}
                    setSelectedAlertCameraInfo={setSelectedAlertCameraInfo}
                    setIgnoreZonesSelectedAlert={setIgnoreZonesSelectedAlert}
                    setAlertsIndexOrder={setAlertsIndexOrder}
                    setRefreshInterval={setRefreshInterval}
                    currentName={currentName}
                    setCurrentName={setCurrentName}
                    pageLoadedNumber={pageLoadedNumber}
                    setPageLoadedNumber={setPageLoadedNumber}
                    setRefreshingData={setRefreshingData}
                    cameraOptions={cameraOptions}
                    siteOptions={siteOptions}
                    scrollToTop={scrollToTop}
                    refreshingToken={refreshingToken}
                    setRefreshingToken={setRefreshingToken}
                    setAlertLabels={setAlertLabels}
                    alertLabels={alertLabels}
                    detectionType={detectionType}
                    setDetectionType={setDetectionType}
                    setPeriodHours={setPeriodHours}
                    selectedRow={selectedRow}
                    setQueryLimitHit={setQueryLimitHit}
                    searchParams={searchParams}
                    setSearchParams={setSearchParams}
                    alertsDownloadURL={alertsDownloadURL}
                  />
                  {alertsToDisplay &&
                    !userFilters.start_date &&
                    !userFilters.end_date &&
                    (alertData.length === 100 ? (
                      <p className="pt-[10px] pb-[10px] pl-[105px] font-mulish">
                        {periodHours === 1
                          ? `Displaying ${
                              detectionType === "All" ? 100 : ""
                            } most recent alerts from the past hour.`
                          : `Displaying ${
                              detectionType === "All" ? 100 : ""
                            } most recent alerts from the past ${periodHours} hours.`}
                      </p>
                    ) : (
                      <p className="pt-[10px] pb-[10px] pl-[170px] font-mulish">
                        {periodHours === 1
                          ? `Displaying alerts from the past hour.`
                          : `Displaying alerts from the past ${periodHours} hours.`}
                      </p>
                    ))}
                </div>
              )}
              {refreshingData && (
                <div className="relative">
                  <ThemeProvider theme={theme}>
                    <div className="relative left-[620px]">
                      <CircularProgress color="primary" />
                    </div>
                  </ThemeProvider>
                  <br></br>
                  <p className="relative left-[560px] font-mulish text-[20px]">
                    Refreshing Alerts...
                  </p>
                </div>
              )}
            </div>
          </div>
        )}
        {!pageLoading && !filtersApplied && (
          <div className="relative top-[200px] text-center">
            <p className="font-mulish text-[22px]">
              Please Apply Filters to view data.
            </p>
            <p className="font-mulish text-[18px]">
              To view data for all sites, leave the Site and Camera filters
              empty.
            </p>
          </div>
        )}
      </div>
      <div className="justify-start">
        <NavBar
          navBarCollapsed={navBarCollapsed}
          setNavBarCollapsed={setNavBarCollapsed}
          props={{}}
        />
      </div>
    </div>
  );
};

export default React.memo(AlertsMirror);
