import React from "react";
import { NavBar } from "../../common/NavBar";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import { OrangeTextButton } from "../../common/OrangeTextButton";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import { EditCameraTabs } from "./EditCameraTabs";
import { handleEditInfoButtonClick } from "./EditCameraUtils/handleEditInfoButtonClick";
import { EditCameraSaveChangesButton } from "./EditCameraSaveChangesButton";
import { EditCameraCancelButton } from "./EditCameraCancelButton";
import BackdropLoadingScreen from "../../common/BackdropLoadingScreen";
import { AddImmixAlertDialog } from "./AddImmixAlertDialog";
import { AddSureviewAlertDialog } from "./AddSureViewAlertDialog";
import useWindowDimensions from "../../common/useWindowDimensions";
import RunCircleIcon from "@mui/icons-material/RunCircle";
import { EditCameraAlerts } from "./EditCameraAlerts";
import { handleTriggerMotionAxis } from "./EditCameraUtils/handleTriggerMotionAxis";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { getEditCameraData } from "./EditCameraUtils/getEditCameraData";
import { ImmixTestSuccessDialog } from "./ImmixTestSuccessDialog";
import { ImmixTestFailDialog } from "./ImmixTestFailDialog";
import { createIgnoreZonesLinksArray } from "../EditIgnoreZones/EditIgnoreZonesUtils/createIgnoreZonesLinksArray";
import { V1RedirectDialog } from "./V1RedirectDialog";
import { getAllCameras } from "./EditCameraUtils/getAllCameras";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Tooltip } from "@mui/material";
import { resetState } from "./EditCameraUtils/resetState";
import { AddSentinelAlertDialog } from "./AddSentinelAlertDialog";
import ErrorIcon from "@mui/icons-material/Error";
import { AddBoldAlertDialog } from "./AddBoldAlertDialog";
import { AddPatriotAlertDialog } from "./AddPatriotAlertDialog";
import { AddUSMonitoringAlertDialog } from "./AddUSMonitoringAlertDialog";

export const getNumbersAfterSites = (url: string) => {
  const match = url.match(/\/sites\/(\d+)/);
  if (match) {
    return match[1];
  } else {
    return "";
  }
};

export const getNumbersAfterEditCamera = (url: string) => {
  const match = url.match(/\/editcamera\/(\d+)/);
  if (match) {
    return match[1];
  } else {
    return "";
  }
};

export const getNumbersAfterAboutSite = (url: string) => {
  const match = url.match(/\/aboutsite\/(\d+)/);
  if (match) {
    return match[1];
  } else {
    return "";
  }
};

export const EditCamera = ({
  permissions,
  navBarCollapsed,
  setNavBarCollapsed,
}: {
  permissions: any;
  navBarCollapsed: boolean;
  setNavBarCollapsed: Dispatcher<boolean>;
}) => {
  const [cameraDataLoaded, setCameraDataLoaded] = React.useState(false);
  const [cameraObject, setCameraObject] = React.useState<any>({});
  const [siteInfo, setSiteInfo] = React.useState<any>({});
  const [clickedTab, setClickedTab] = React.useState("one");
  const [editMode, setEditMode] = React.useState(false);
  const [addImmixAlertDialogOpen, setAddImmixAlertDialogOpen] =
    React.useState(false);
  const [addSureviewAlertDialogOpen, setAddSureviewAlertDialogOpen] =
    React.useState(false);
  const [addSentinelAlertDialogOpen, setAddSentinelAlertDialogOpen] =
    React.useState(false);
  const [addBoldAlertDialogOpen, setAddBoldAlertDialogOpen] =
    React.useState(false);
  const [addPatriotAlertDialogOpen, setAddPatriotAlertDialogOpen] =
    React.useState(false);
  const [addUSMonitoringAlertDialogOpen, setAddUSMonitoringAlertDialogOpen] =
    React.useState(false);

  const { height, width } = useWindowDimensions();

  const [cameraGeneralInfo, setCameraGeneralInfo] = React.useState({});
  const [cameraGeneralInfoBackup, setCameraGeneralInfoBackup] =
    React.useState<any>({});
  const [patchObject, setPatchObject] = React.useState({});
  const [generalInfoChangesMade, setGeneralInfoChangesMade] =
    React.useState(false);
  const [activeChangeMade, setActiveChangeMade] = React.useState(false);
  const [axisCamera, setAxisCamera] = React.useState(false);
  const [mobotixCamera, setMobotixCamera] = React.useState(false);

  const [activeTab, setActiveTab] = React.useState(<></>);

  const [backdropLoadingScreenOn, setBackdropLoadingScreenOn] =
    React.useState(true);

  const [immixAlertsOnCamera, setImmixAlertsOnCamera] = React.useState(false);
  const [immixAlerts, setImmixAlerts] = React.useState<any>([]);

  const [sureViewAlertsOnCamera, setSureViewAlertsOnCamera] =
    React.useState(false);
  const [sureviewAlerts, setSureviewAlerts] = React.useState<any>([]);

  const [sentinelAlertsOnCamera, setSentinelAlertsOnCamera] =
    React.useState(false);
  const [sentinelAlerts, setSentinelAlerts] = React.useState<any>([]);

  const [boldAlertsOnCamera, setBoldAlertsOnCamera] = React.useState(false);
  const [boldAlerts, setBoldAlerts] = React.useState<any>([]);

  const [patriotAlertsOnCamera, setPatriotAlertsOnCamera] =
    React.useState(false);
  const [patriotAlerts, setPatriotAlerts] = React.useState<any>([]);

  const [uSMonitoringAlertsOnCamera, setUSMonitoringAlertsOnCamera] =
    React.useState(false);
  const [uSMonitoringAlerts, setUSMonitoringAlerts] = React.useState<any>([]);

  const handleSitesBreadcrumbClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.preventDefault();
    localStorage.setItem("level0Click", "false");
    localStorage.setItem("navigatedFromSitesAboutPage", "false");
    navigate("/sites");
  };

  const handleSingularSiteBreadcrumbClick = () => {
    navigate(`/sites/aboutsite/${getNumbersAfterSites(location.href)}`);
    localStorage.setItem("navigatedFromEditCameraPage", "true");
  };

  const [ignoreZonesBreadCrumbs, setIgnoreZonesBreadCrumbs] = React.useState(
    <></>
  );

  const [editCameraAlerts, setEditCameraAlerts] = React.useState({
    triggerMotionSuccess: false,
    triggerMotionFail: false,
  });

  const [recipientsConfiguredData, setRecipientsConfiguredData] =
    React.useState([]);

  const [titlesArray, setTitlesArray] = React.useState([
    "Name",
    "Recipient Type",
    "Host",
    "Port",
    "Email From",
    "Email To",
  ]);

  const [actionRulesData, setActionRulesData] = React.useState([]);

  const [actionRulesTitlesArray, setActionRulesTitlesArray] = React.useState([
    "Name",
    "Host",
    "Port",
    "Email From",
    "Email To",
    "Message",
    "Enabled",
    "Profile",
  ]);

  const [applicationsConfiguredData, setApplicationsConfiguredData] =
    React.useState([]);

  const [timeTablesData, setTimeTablesData] = React.useState([]);

  const [iPNotifyData, setIPNotifyData] = React.useState([]);

  const [actionHandlerData, setActionHandlerData] = React.useState([]);

  const [mobotixFinished, setMobotixFinished] = React.useState({
    timeTable: false,
    iPNotify: false,
    actionHandler: false,
  });

  const [axisFinished, setAxisFinished] = React.useState({
    recipient: false,
    actionRules: false,
    application: false,
  });

  const [streamsObject, setStreamsObject] = React.useState<any>({});

  const [activeProducts, setActiveProducts] = React.useState<any>([]);

  const [sendingImmixTest, setSendingImmixTest] = React.useState(false);

  const [immixTestSuccessDialogOpen, setImmixTestSuccessDialogOpen] =
    React.useState(false);

  const [immixTestFailDialogOpen, setImmixTestFailDialogOpen] =
    React.useState(false);

  const [v1RedirectDialogOpen, setv1RedirectDialogOpen] = React.useState(false);

  const [srcImages, setSrcImages] = React.useState({});

  const [cameraInfoRow, setCameraInfoRow] = React.useState<any>("");

  const [cameraWebhookData, setCameraWebhookData] = React.useState("");

  const [cameraWebhooksPatchObject, setCameraWebhooksPatchObject] =
    React.useState({});

  const [cameraWebhooksChangesMade, setCameraWebhooksChangesMade] =
    React.useState(false);

  const navigate = useNavigate();

  // const { fetchRecipientsConfiguredData }: any = useAxisTabStore();

  const [productsData, setProductsData] = React.useState([
    {
      name: "Intruder",
      label_name: "intruder",
      open: false,
      metrics: {},
    },
    {
      name: "Intruder Plus",
      label_name: "intruderPlus",
      open: false,
      metrics: {},
    },
    { name: "Gun", label_name: "gun", open: false, metrics: {} },
    {
      name: "Crowd",
      label_name: "crowd",
      open: false,
      metrics: {},
    },
    {
      name: "Loitering",
      label_name: "loitering",
      open: false,
      metrics: {},
    },
    {
      name: "Fall Person",
      label_name: "fallPerson",
      open: false,
      metrics: {},
    },
    // {
    //   name: "Slip and Fall (old model)",
    //   label_name: "fall",
    //   open: false,
    //   metrics: {
    //     fall: fallMetrics,
    //   },
    // },
    {
      name: "No Hard Hat",
      label_name: "noHardHat",
      open: false,
      metrics: {},
    },
    { name: "Mask", label_name: "mask", open: false, metrics: {} },
    {
      name: "Social Distancing",
      label_name: "socialDistancing",
      open: false,
      metrics: {},
    },
    {
      name: "Left Object",
      label_name: "leftObject",
      open: false,
      metrics: {},
    },
    {
      name: "Package",
      label_name: "package",
      open: false,
      metrics: {},
    },
    { name: "Fire", label_name: "fire", open: false, metrics: {} },
    {
      name: "Vehicle Logo ID",
      label_name: "vehicleLogoId",
      open: false,
      metrics: {},
    },
    // {
    //   name: "People Flow",
    //   label_name: "peopleFlow",
    //   open: false,
    //   metrics: peopleFlowMetrics,
    // },
    {
      name: "Non-UPS",
      label_name: "nonUps",
      open: false,
      metrics: {},
    },
  ]);

  const [backupProductsData, setBackupProductsData] =
    React.useState(productsData);

  const [allTabsOpen, setAllTabsOpen] = React.useState(false);

  const [allCamerasData, setAllCamerasData] = React.useState<any[]>([]);

  const [cameraIndex, setCameraIndex] = React.useState(0);

  React.useEffect(() => {
    if (
      Number(getNumbersAfterSites(location.href)) &&
      Number(getNumbersAfterEditCamera(location.href))
    ) {
      localStorage.setItem("editCamerActiveTab", "one");
      localStorage.setItem(
        "currentCameraId",
        getNumbersAfterEditCamera(location.href)
      );
      getEditCameraData(
        navigate,
        getNumbersAfterSites(location.href),
        getNumbersAfterEditCamera(location.href),
        setSiteInfo,
        setCameraGeneralInfo,
        setCameraObject,
        setCameraDataLoaded,
        setBackdropLoadingScreenOn,
        setImmixAlertsOnCamera,
        setIgnoreZonesBreadCrumbs,
        createIgnoreZonesLinksArray,
        handleSitesBreadcrumbClick,
        handleSingularSiteBreadcrumbClick,
        setSureViewAlertsOnCamera,
        setAxisCamera,
        setMobotixFinished,
        setRecipientsConfiguredData,
        setTitlesArray,
        setActionRulesData,
        setActionRulesTitlesArray,
        setApplicationsConfiguredData,
        mobotixFinished,
        setAxisFinished,
        axisFinished,
        setMobotixCamera,
        setTimeTablesData,
        setIPNotifyData,
        setActionHandlerData,
        setCameraGeneralInfoBackup,
        setImmixAlerts,
        setSureviewAlerts,
        setActiveProducts,
        setActiveTab,
        actionRulesTitlesArray,
        applicationsConfiguredData,
        cameraGeneralInfo,
        actionRulesData,
        setSrcImages,
        permissions,
        setCameraInfoRow,
        width,
        setCameraWebhookData,
        setProductsData,
        setBackupProductsData,
        setSentinelAlertsOnCamera,
        setSentinelAlerts,
        setBoldAlertsOnCamera,
        setBoldAlerts,
        setPatriotAlertsOnCamera,
        setPatriotAlerts,
        setUSMonitoringAlertsOnCamera,
        setUSMonitoringAlerts
      );
      getAllCameras(
        Number(getNumbersAfterSites(location.href)),
        Number(getNumbersAfterEditCamera(location.href)),
        setAllCamerasData,
        setCameraIndex
      );
    } else {
      navigate("/*");
    }
  }, []);

  const editIgnoreZonesText = (
    <>
      <EditIcon fontSize="small" />
      &nbsp;Edit Ignore Zones
    </>
  );

  const editInformationText = (
    <>
      <EditIcon fontSize="small" />
      &nbsp;Edit Information
    </>
  );

  const triggerMotionText = (
    <>
      <RunCircleIcon />
      &nbsp;Trigger Motion on Camera
    </>
  );

  const seeAlertText = (
    <>
      <ErrorIcon fontSize="small" />
      &nbsp;See Alerts
    </>
  );

  return (
    <div>
      {v1RedirectDialogOpen && (
        <V1RedirectDialog
          v1RedirectDialogOpen={v1RedirectDialogOpen}
          setv1RedirectDialogOpen={setv1RedirectDialogOpen}
        />
      )}
      {addImmixAlertDialogOpen && (
        <AddImmixAlertDialog
          setAddImmixAlertDialogOpen={setAddImmixAlertDialogOpen}
          addImmixAlertDialogOpen={addImmixAlertDialogOpen}
          immixAlerts={immixAlerts}
          setBackdropLoadingScreenOn={setBackdropLoadingScreenOn}
          setCameraGeneralInfo={setCameraGeneralInfo}
          setCameraGeneralInfoBackup={setCameraGeneralInfoBackup}
          setGeneralInfoChangesMade={setGeneralInfoChangesMade}
          setPatchObject={setPatchObject}
          setImmixAlerts={setImmixAlerts}
          setActiveTab={setActiveTab}
          cameraGeneralInfoBackup={cameraGeneralInfoBackup}
          setIgnoreZonesBreadCrumbs={setIgnoreZonesBreadCrumbs}
          handleSitesBreadcrumbClick={handleSitesBreadcrumbClick}
          handleSingularSiteBreadcrumbClick={handleSingularSiteBreadcrumbClick}
          cameraGeneralInfo={cameraGeneralInfo}
          setSendingImmixTest={setSendingImmixTest}
          setImmixTestSuccessDialogOpen={setImmixTestSuccessDialogOpen}
          setImmixTestFailDialogOpen={setImmixTestFailDialogOpen}
        />
      )}
      {addSentinelAlertDialogOpen && (
        <AddSentinelAlertDialog
          setAddSentinelAlertDialogOpen={setAddSentinelAlertDialogOpen}
          addSentinelAlertDialogOpen={addSentinelAlertDialogOpen}
          sentinelAlerts={sentinelAlerts}
          setBackdropLoadingScreenOn={setBackdropLoadingScreenOn}
          setCameraGeneralInfo={setCameraGeneralInfo}
          setCameraGeneralInfoBackup={setCameraGeneralInfoBackup}
          setGeneralInfoChangesMade={setGeneralInfoChangesMade}
          setPatchObject={setPatchObject}
          setSentinelAlerts={setSentinelAlerts}
          setActiveTab={setActiveTab}
          cameraGeneralInfoBackup={cameraGeneralInfoBackup}
          setIgnoreZonesBreadCrumbs={setIgnoreZonesBreadCrumbs}
          handleSitesBreadcrumbClick={handleSitesBreadcrumbClick}
          handleSingularSiteBreadcrumbClick={handleSingularSiteBreadcrumbClick}
          cameraGeneralInfo={cameraGeneralInfo}
        />
      )}
      {addBoldAlertDialogOpen && (
        <AddBoldAlertDialog
          setAddBoldAlertDialogOpen={setAddBoldAlertDialogOpen}
          addBoldAlertDialogOpen={addBoldAlertDialogOpen}
          boldAlerts={boldAlerts}
          setBackdropLoadingScreenOn={setBackdropLoadingScreenOn}
          setCameraGeneralInfo={setCameraGeneralInfo}
          setCameraGeneralInfoBackup={setCameraGeneralInfoBackup}
          setGeneralInfoChangesMade={setGeneralInfoChangesMade}
          setPatchObject={setPatchObject}
          setBoldAlerts={setBoldAlerts}
          setActiveTab={setActiveTab}
          cameraGeneralInfoBackup={cameraGeneralInfoBackup}
          setIgnoreZonesBreadCrumbs={setIgnoreZonesBreadCrumbs}
          handleSitesBreadcrumbClick={handleSitesBreadcrumbClick}
          handleSingularSiteBreadcrumbClick={handleSingularSiteBreadcrumbClick}
          cameraGeneralInfo={cameraGeneralInfo}
        />
      )}
      {addPatriotAlertDialogOpen && (
        <AddPatriotAlertDialog
          setAddPatriotAlertDialogOpen={setAddPatriotAlertDialogOpen}
          addPatriotAlertDialogOpen={addPatriotAlertDialogOpen}
          patriotAlerts={patriotAlerts}
          setBackdropLoadingScreenOn={setBackdropLoadingScreenOn}
          setCameraGeneralInfo={setCameraGeneralInfo}
          setCameraGeneralInfoBackup={setCameraGeneralInfoBackup}
          setGeneralInfoChangesMade={setGeneralInfoChangesMade}
          setPatchObject={setPatchObject}
          setPatriotAlerts={setPatriotAlerts}
          setActiveTab={setActiveTab}
          cameraGeneralInfoBackup={cameraGeneralInfoBackup}
          setIgnoreZonesBreadCrumbs={setIgnoreZonesBreadCrumbs}
          handleSitesBreadcrumbClick={handleSitesBreadcrumbClick}
          handleSingularSiteBreadcrumbClick={handleSingularSiteBreadcrumbClick}
          cameraGeneralInfo={cameraGeneralInfo}
        />
      )}
      {addUSMonitoringAlertDialogOpen && (
        <AddUSMonitoringAlertDialog
          setAddUSMonitoringAlertDialogOpen={setAddUSMonitoringAlertDialogOpen}
          addUSMonitoringAlertDialogOpen={addUSMonitoringAlertDialogOpen}
          uSMonitoringAlerts={uSMonitoringAlerts}
          setBackdropLoadingScreenOn={setBackdropLoadingScreenOn}
          setCameraGeneralInfo={setCameraGeneralInfo}
          setCameraGeneralInfoBackup={setCameraGeneralInfoBackup}
          setGeneralInfoChangesMade={setGeneralInfoChangesMade}
          setPatchObject={setPatchObject}
          setUSMonitoringAlerts={setUSMonitoringAlerts}
          setActiveTab={setActiveTab}
          cameraGeneralInfoBackup={cameraGeneralInfoBackup}
          setIgnoreZonesBreadCrumbs={setIgnoreZonesBreadCrumbs}
          handleSitesBreadcrumbClick={handleSitesBreadcrumbClick}
          handleSingularSiteBreadcrumbClick={handleSingularSiteBreadcrumbClick}
          cameraGeneralInfo={cameraGeneralInfo}
        />
      )}
      {immixTestSuccessDialogOpen && (
        <ImmixTestSuccessDialog
          immixTestSuccessDialogOpen={immixTestSuccessDialogOpen}
          setImmixTestSuccessDialogOpen={setImmixTestSuccessDialogOpen}
        />
      )}
      {immixTestFailDialogOpen && (
        <ImmixTestFailDialog
          immixTestFailDialogOpen={immixTestFailDialogOpen}
          setImmixTestFailDialogOpen={setImmixTestFailDialogOpen}
        />
      )}
      {addSureviewAlertDialogOpen && (
        <AddSureviewAlertDialog
          setAddSureviewAlertDialogOpen={setAddSureviewAlertDialogOpen}
          addSureviewAlertDialogOpen={addSureviewAlertDialogOpen}
          sureViewAlerts={sureviewAlerts}
          setBackdropLoadingScreenOn={setBackdropLoadingScreenOn}
          setCameraGeneralInfo={setCameraGeneralInfo}
          setCameraGeneralInfoBackup={setCameraGeneralInfoBackup}
          setGeneralInfoChangesMade={setGeneralInfoChangesMade}
          setPatchObject={setPatchObject}
          setSureviewAlerts={setSureviewAlerts}
          setActiveTab={setActiveTab}
          cameraGeneralInfoBackup={cameraGeneralInfoBackup}
          setIgnoreZonesBreadCrumbs={setIgnoreZonesBreadCrumbs}
          handleSitesBreadcrumbClick={handleSitesBreadcrumbClick}
          handleSingularSiteBreadcrumbClick={handleSingularSiteBreadcrumbClick}
          cameraGeneralInfo={cameraGeneralInfo}
        />
      )}
      <div>
        <BackdropLoadingScreen openState={backdropLoadingScreenOn} />
        <BackdropLoadingScreen openState={sendingImmixTest} />
        <EditCameraAlerts
          editCameraAlerts={editCameraAlerts}
          setEditCameraAlerts={setEditCameraAlerts}
          cameraName={cameraObject.camera_name}
        />
        {cameraDataLoaded && cameraInfoRow && (
          <div className="bg-actuate-grey absolute w-full h-full overflow-scroll">
            <div
              className={
                !navBarCollapsed
                  ? "absolute top-[15px] left-[150px] right-[1px] h-[59px] min-w-[1250px] bg-white"
                  : "absolute top-[15px] left-[75px] right-[1px] h-[59px] min-w-[1250px] bg-white"
              }
            >
              <div className="absolute top-[7px] min-w-[1250px]">
                {ignoreZonesBreadCrumbs}
              </div>
            </div>
            <div
              className={
                !navBarCollapsed
                  ? "absolute top-[92px] left-[150px] min-w-[1250px] right-[1px] h-[450px] bg-white"
                  : "absolute top-[92px] left-[75px] min-w-[1250px] right-[1px] h-[450px] bg-white"
              }
            >
              <div className="flex">
                {allCamerasData.length > 1 && (
                  <div className="pt-[200px] w-[40px]">
                    <Tooltip
                      title={`Previous Camera: ${
                        cameraIndex === 0
                          ? allCamerasData[allCamerasData.length - 1]
                              .camera_name
                          : allCamerasData[cameraIndex - 1].camera_name
                      }`}
                      placement="top"
                      sx={{ fontSize: "12px", fontFamily: "mulish" }}
                    >
                      <div>
                        <OrangeTextButton
                          onClick={() => {
                            resetState(
                              setCameraDataLoaded,
                              setCameraObject,
                              setSiteInfo,
                              setClickedTab,
                              setEditMode,
                              setAddImmixAlertDialogOpen,
                              setAddSureviewAlertDialogOpen,
                              setCameraGeneralInfo,
                              setCameraGeneralInfoBackup,
                              setPatchObject,
                              setGeneralInfoChangesMade,
                              setActiveChangeMade,
                              setAxisCamera,
                              setMobotixCamera,
                              setActiveTab,
                              setBackdropLoadingScreenOn,
                              setImmixAlertsOnCamera,
                              setImmixAlerts,
                              setSureViewAlertsOnCamera,
                              setSureviewAlerts,
                              setIgnoreZonesBreadCrumbs,
                              setEditCameraAlerts,
                              setRecipientsConfiguredData,
                              setTitlesArray,
                              setActionRulesData,
                              setActionRulesTitlesArray,
                              setApplicationsConfiguredData,
                              setTimeTablesData,
                              setIPNotifyData,
                              setActionHandlerData,
                              setMobotixFinished,
                              setAxisFinished,
                              setStreamsObject,
                              setActiveProducts,
                              setSendingImmixTest,
                              setImmixTestSuccessDialogOpen,
                              setImmixTestFailDialogOpen,
                              setv1RedirectDialogOpen,
                              setSrcImages,
                              setCameraInfoRow,
                              setCameraWebhookData,
                              setCameraWebhooksPatchObject,
                              setCameraWebhooksChangesMade,
                              setProductsData,
                              setBackupProductsData,
                              setAllTabsOpen,
                              setAllCamerasData,
                              setCameraIndex,
                              setSentinelAlertsOnCamera,
                              setSentinelAlerts,
                              setBoldAlerts,
                              setBoldAlertsOnCamera,
                              setPatriotAlerts,
                              setPatriotAlertsOnCamera,
                              setUSMonitoringAlerts,
                              setUSMonitoringAlertsOnCamera
                            );
                            setTimeout(() => {
                              if (cameraIndex === 0) {
                                navigate(
                                  `/sites/${getNumbersAfterSites(
                                    location.href
                                  )}/editcamera/${
                                    allCamerasData[allCamerasData.length - 1].id
                                  }`
                                );
                                localStorage.setItem(
                                  "editCamerActiveTab",
                                  "one"
                                );
                                localStorage.setItem(
                                  "currentCameraId",
                                  getNumbersAfterEditCamera(location.href)
                                );
                                getEditCameraData(
                                  navigate,
                                  getNumbersAfterSites(location.href),
                                  getNumbersAfterEditCamera(location.href),
                                  setSiteInfo,
                                  setCameraGeneralInfo,
                                  setCameraObject,
                                  setCameraDataLoaded,
                                  setBackdropLoadingScreenOn,
                                  setImmixAlertsOnCamera,
                                  setIgnoreZonesBreadCrumbs,
                                  createIgnoreZonesLinksArray,
                                  handleSitesBreadcrumbClick,
                                  handleSingularSiteBreadcrumbClick,
                                  setSureViewAlertsOnCamera,
                                  setAxisCamera,
                                  setMobotixFinished,
                                  setRecipientsConfiguredData,
                                  setTitlesArray,
                                  setActionRulesData,
                                  setActionRulesTitlesArray,
                                  setApplicationsConfiguredData,
                                  mobotixFinished,
                                  setAxisFinished,
                                  axisFinished,
                                  setMobotixCamera,
                                  setTimeTablesData,
                                  setIPNotifyData,
                                  setActionHandlerData,
                                  setCameraGeneralInfoBackup,
                                  setImmixAlerts,
                                  setSureviewAlerts,
                                  setActiveProducts,
                                  setActiveTab,
                                  actionRulesTitlesArray,
                                  applicationsConfiguredData,
                                  cameraGeneralInfo,
                                  actionRulesData,
                                  setSrcImages,
                                  permissions,
                                  setCameraInfoRow,
                                  width,
                                  setCameraWebhookData,
                                  setProductsData,
                                  setBackupProductsData,
                                  setSentinelAlertsOnCamera,
                                  setSentinelAlertsOnCamera,
                                  setBoldAlertsOnCamera,
                                  setBoldAlerts,
                                  setPatriotAlertsOnCamera,
                                  setPatriotAlerts,
                                  setUSMonitoringAlertsOnCamera,
                                  setUSMonitoringAlerts
                                );
                                getAllCameras(
                                  Number(getNumbersAfterSites(location.href)),
                                  Number(
                                    getNumbersAfterEditCamera(location.href)
                                  ),
                                  setAllCamerasData,
                                  setCameraIndex
                                );
                              } else {
                                navigate(
                                  `/sites/${getNumbersAfterSites(
                                    location.href
                                  )}/editcamera/${
                                    allCamerasData[cameraIndex - 1].id
                                  }`
                                );
                                localStorage.setItem(
                                  "editCamerActiveTab",
                                  "one"
                                );
                                localStorage.setItem(
                                  "currentCameraId",
                                  getNumbersAfterEditCamera(location.href)
                                );
                                getEditCameraData(
                                  navigate,
                                  getNumbersAfterSites(location.href),
                                  getNumbersAfterEditCamera(location.href),
                                  setSiteInfo,
                                  setCameraGeneralInfo,
                                  setCameraObject,
                                  setCameraDataLoaded,
                                  setBackdropLoadingScreenOn,
                                  setImmixAlertsOnCamera,
                                  setIgnoreZonesBreadCrumbs,
                                  createIgnoreZonesLinksArray,
                                  handleSitesBreadcrumbClick,
                                  handleSingularSiteBreadcrumbClick,
                                  setSureViewAlertsOnCamera,
                                  setAxisCamera,
                                  setMobotixFinished,
                                  setRecipientsConfiguredData,
                                  setTitlesArray,
                                  setActionRulesData,
                                  setActionRulesTitlesArray,
                                  setApplicationsConfiguredData,
                                  mobotixFinished,
                                  setAxisFinished,
                                  axisFinished,
                                  setMobotixCamera,
                                  setTimeTablesData,
                                  setIPNotifyData,
                                  setActionHandlerData,
                                  setCameraGeneralInfoBackup,
                                  setImmixAlerts,
                                  setSureviewAlerts,
                                  setActiveProducts,
                                  setActiveTab,
                                  actionRulesTitlesArray,
                                  applicationsConfiguredData,
                                  cameraGeneralInfo,
                                  actionRulesData,
                                  setSrcImages,
                                  permissions,
                                  setCameraInfoRow,
                                  width,
                                  setCameraWebhookData,
                                  setProductsData,
                                  setBackupProductsData,
                                  setSentinelAlertsOnCamera,
                                  setSentinelAlerts,
                                  setBoldAlertsOnCamera,
                                  setBoldAlerts,
                                  setPatriotAlertsOnCamera,
                                  setPatriotAlerts,
                                  setUSMonitoringAlertsOnCamera,
                                  setUSMonitoringAlerts
                                );
                                getAllCameras(
                                  Number(getNumbersAfterSites(location.href)),
                                  Number(
                                    getNumbersAfterEditCamera(location.href)
                                  ),
                                  setAllCamerasData,
                                  setCameraIndex
                                );
                              }
                            }, 1);
                          }}
                          text={
                            <ArrowBackIosIcon
                              fontSize="large"
                              id="backButton"
                            />
                          }
                          disabled={allCamerasData.length < 2}
                        />
                      </div>
                    </Tooltip>
                  </div>
                )}

                <div>
                  <Table
                    sx={{ minWidth: 1000, fontFamily: "Mulish" }}
                    aria-label="simple table"
                  >
                    <TableBody>{cameraInfoRow}</TableBody>
                  </Table>
                </div>
                {allCamerasData.length > 1 && (
                  <div className="pt-[200px] pl-[140px]">
                    <Tooltip
                      title={`Next Camera: ${
                        cameraIndex + 1 === allCamerasData.length
                          ? allCamerasData[0].camera_name
                          : allCamerasData[cameraIndex + 1].camera_name
                      }`}
                      placement="top"
                      sx={{ fontSize: "12px", fontFamily: "mulish" }}
                    >
                      <div>
                        <OrangeTextButton
                          onClick={() => {
                            resetState(
                              setCameraDataLoaded,
                              setCameraObject,
                              setSiteInfo,
                              setClickedTab,
                              setEditMode,
                              setAddImmixAlertDialogOpen,
                              setAddSureviewAlertDialogOpen,
                              setCameraGeneralInfo,
                              setCameraGeneralInfoBackup,
                              setPatchObject,
                              setGeneralInfoChangesMade,
                              setActiveChangeMade,
                              setAxisCamera,
                              setMobotixCamera,
                              setActiveTab,
                              setBackdropLoadingScreenOn,
                              setImmixAlertsOnCamera,
                              setImmixAlerts,
                              setSureViewAlertsOnCamera,
                              setSureviewAlerts,
                              setIgnoreZonesBreadCrumbs,
                              setEditCameraAlerts,
                              setRecipientsConfiguredData,
                              setTitlesArray,
                              setActionRulesData,
                              setActionRulesTitlesArray,
                              setApplicationsConfiguredData,
                              setTimeTablesData,
                              setIPNotifyData,
                              setActionHandlerData,
                              setMobotixFinished,
                              setAxisFinished,
                              setStreamsObject,
                              setActiveProducts,
                              setSendingImmixTest,
                              setImmixTestSuccessDialogOpen,
                              setImmixTestFailDialogOpen,
                              setv1RedirectDialogOpen,
                              setSrcImages,
                              setCameraInfoRow,
                              setCameraWebhookData,
                              setCameraWebhooksPatchObject,
                              setCameraWebhooksChangesMade,
                              setProductsData,
                              setBackupProductsData,
                              setAllTabsOpen,
                              setAllCamerasData,
                              setCameraIndex,
                              setSentinelAlertsOnCamera,
                              setSentinelAlerts,
                              setBoldAlerts,
                              setBoldAlertsOnCamera,
                              setPatriotAlertsOnCamera,
                              setPatriotAlerts,
                              setUSMonitoringAlertsOnCamera,
                              setUSMonitoringAlerts
                            );
                            setTimeout(() => {
                              if (cameraIndex + 1 === allCamerasData.length) {
                                navigate(
                                  `/sites/${getNumbersAfterSites(
                                    location.href
                                  )}/editcamera/${allCamerasData[0].id}`
                                );
                                getEditCameraData(
                                  navigate,
                                  getNumbersAfterSites(location.href),
                                  getNumbersAfterEditCamera(location.href),
                                  setSiteInfo,
                                  setCameraGeneralInfo,
                                  setCameraObject,
                                  setCameraDataLoaded,
                                  setBackdropLoadingScreenOn,
                                  setImmixAlertsOnCamera,
                                  setIgnoreZonesBreadCrumbs,
                                  createIgnoreZonesLinksArray,
                                  handleSitesBreadcrumbClick,
                                  handleSingularSiteBreadcrumbClick,
                                  setSureViewAlertsOnCamera,
                                  setAxisCamera,
                                  setMobotixFinished,
                                  setRecipientsConfiguredData,
                                  setTitlesArray,
                                  setActionRulesData,
                                  setActionRulesTitlesArray,
                                  setApplicationsConfiguredData,
                                  mobotixFinished,
                                  setAxisFinished,
                                  axisFinished,
                                  setMobotixCamera,
                                  setTimeTablesData,
                                  setIPNotifyData,
                                  setActionHandlerData,
                                  setCameraGeneralInfoBackup,
                                  setImmixAlerts,
                                  setSureviewAlerts,
                                  setActiveProducts,
                                  setActiveTab,
                                  actionRulesTitlesArray,
                                  applicationsConfiguredData,
                                  cameraGeneralInfo,
                                  actionRulesData,
                                  setSrcImages,
                                  permissions,
                                  setCameraInfoRow,
                                  width,
                                  setCameraWebhookData,
                                  setProductsData,
                                  setBackupProductsData,
                                  setSentinelAlertsOnCamera,
                                  setSentinelAlerts,
                                  setBoldAlertsOnCamera,
                                  setBoldAlerts,
                                  setPatriotAlertsOnCamera,
                                  setPatriotAlerts,
                                  setUSMonitoringAlertsOnCamera,
                                  setUSMonitoringAlerts
                                );
                                getAllCameras(
                                  Number(getNumbersAfterSites(location.href)),
                                  Number(
                                    getNumbersAfterEditCamera(location.href)
                                  ),
                                  setAllCamerasData,
                                  setCameraIndex
                                );
                              } else {
                                navigate(
                                  `/sites/${getNumbersAfterSites(
                                    location.href
                                  )}/editcamera/${
                                    allCamerasData[cameraIndex + 1].id
                                  }`
                                );
                                getEditCameraData(
                                  navigate,
                                  getNumbersAfterSites(location.href),
                                  getNumbersAfterEditCamera(location.href),
                                  setSiteInfo,
                                  setCameraGeneralInfo,
                                  setCameraObject,
                                  setCameraDataLoaded,
                                  setBackdropLoadingScreenOn,
                                  setImmixAlertsOnCamera,
                                  setIgnoreZonesBreadCrumbs,
                                  createIgnoreZonesLinksArray,
                                  handleSitesBreadcrumbClick,
                                  handleSingularSiteBreadcrumbClick,
                                  setSureViewAlertsOnCamera,
                                  setAxisCamera,
                                  setMobotixFinished,
                                  setRecipientsConfiguredData,
                                  setTitlesArray,
                                  setActionRulesData,
                                  setActionRulesTitlesArray,
                                  setApplicationsConfiguredData,
                                  mobotixFinished,
                                  setAxisFinished,
                                  axisFinished,
                                  setMobotixCamera,
                                  setTimeTablesData,
                                  setIPNotifyData,
                                  setActionHandlerData,
                                  setCameraGeneralInfoBackup,
                                  setImmixAlerts,
                                  setSureviewAlerts,
                                  setActiveProducts,
                                  setActiveTab,
                                  actionRulesTitlesArray,
                                  applicationsConfiguredData,
                                  cameraGeneralInfo,
                                  actionRulesData,
                                  setSrcImages,
                                  permissions,
                                  setCameraInfoRow,
                                  width,
                                  setCameraWebhookData,
                                  setProductsData,
                                  setBackupProductsData,
                                  setSentinelAlertsOnCamera,
                                  setSentinelAlerts,
                                  setBoldAlertsOnCamera,
                                  setBoldAlerts,
                                  setPatriotAlertsOnCamera,
                                  setPatriotAlerts,
                                  setUSMonitoringAlertsOnCamera,
                                  setUSMonitoringAlerts
                                );
                                getAllCameras(
                                  Number(getNumbersAfterSites(location.href)),
                                  Number(
                                    getNumbersAfterEditCamera(location.href)
                                  ),
                                  setAllCamerasData,
                                  setCameraIndex
                                );
                              }
                            }, 1);
                          }}
                          text={
                            <ArrowForwardIosIcon
                              fontSize="large"
                              id="forwardButton"
                            />
                          }
                          disabled={allCamerasData.length < 2}
                        />
                      </div>
                    </Tooltip>
                  </div>
                )}
              </div>
              <div className="absolute right-[90px] top-[10px]">
                {permissions.edit_cameras && (
                  <OrangeTextButton
                    onClick={() => {
                      navigate(
                        `/alerts?group=${
                          siteInfo.breadcrumb_info[0].id
                        }&site=${getNumbersAfterSites(
                          location.href
                        )}&camera=${getNumbersAfterEditCamera(location.href)}`
                      );
                    }}
                    text={seeAlertText}
                    disabled={false}
                  />
                )}
              </div>
              <div className="absolute left-[10px] top-[330px]">
                {axisCamera && (
                  <OrangeTextButton
                    onClick={() => {
                      setBackdropLoadingScreenOn(true);
                      handleTriggerMotionAxis(
                        cameraObject.id,
                        navigate,
                        setBackdropLoadingScreenOn,
                        setEditCameraAlerts
                      );
                    }}
                    text={triggerMotionText}
                    disabled={false}
                  />
                )}
              </div>
            </div>
            <div
              className={
                !navBarCollapsed
                  ? "absolute top-[557px] left-[150px] right-[1px] min-w-[1250px] h-[59px] bg-white"
                  : "absolute top-[557px] left-[75px] right-[1px] min-w-[1250px] h-[59px] bg-white"
              }
            >
              <p className="font-black font-mulish text-[20px] p-3 indent-4">
                Camera Information
              </p>
              <div className="absolute right-[90px] top-[10px]">
                {!editMode &&
                  clickedTab !== "seven" &&
                  permissions.edit_cameras && (
                    <OrangeTextButton
                      onClick={() => {
                        handleEditInfoButtonClick(
                          setEditMode,
                          clickedTab,
                          setActiveTab,
                          siteInfo,
                          cameraGeneralInfo,
                          setCameraGeneralInfo,
                          setPatchObject,
                          setGeneralInfoChangesMade,
                          setActiveChangeMade,
                          immixAlerts,
                          sureviewAlerts,
                          activeProducts,
                          cameraWebhookData,
                          setCameraWebhooksPatchObject,
                          setCameraWebhooksChangesMade,
                          productsData,
                          setProductsData,
                          allTabsOpen,
                          setAllTabsOpen,
                          setBackupProductsData,
                          sentinelAlerts,
                          boldAlerts,
                          patriotAlerts,
                          uSMonitoringAlerts
                        );
                      }}
                      text={editInformationText}
                      disabled={false}
                    />
                  )}
              </div>
              <div className={"absolute top-[10px] right-[90px]"}>
                {editMode && clickedTab !== "seven" && (
                  <EditCameraSaveChangesButton
                    setEditMode={setEditMode}
                    clickedTab={clickedTab}
                    setActiveTab={setActiveTab}
                    siteInfo={siteInfo}
                    cameraGeneralInfo={cameraGeneralInfo}
                    setBackdropLoadingScreenOn={setBackdropLoadingScreenOn}
                    generalInfoChangesMade={generalInfoChangesMade}
                    patchObject={patchObject}
                    setGeneralInfoChangesMade={setGeneralInfoChangesMade}
                    setPatchObject={setPatchObject}
                    setCameraGeneralInfo={setCameraGeneralInfo}
                    cameraGeneralInfoBackup={cameraGeneralInfoBackup}
                    setCameraGeneralInfoBackup={setCameraGeneralInfoBackup}
                    immixAlerts={immixAlerts}
                    activeChangeMade={activeChangeMade}
                    sureviewAlerts={sureviewAlerts}
                    setIgnoreZonesBreadCrumbs={setIgnoreZonesBreadCrumbs}
                    handleSitesBreadcrumbClick={handleSitesBreadcrumbClick}
                    handleSingularSiteBreadcrumbClick={
                      handleSingularSiteBreadcrumbClick
                    }
                    setAddImmixAlertDialogOpen={setAddImmixAlertDialogOpen}
                    setAddSureviewAlertDialogOpen={
                      setAddSureviewAlertDialogOpen
                    }
                    activeProducts={activeProducts}
                    setStreamsObject={setStreamsObject}
                    setActiveProducts={setActiveProducts}
                    setImmixAlertsOnCamera={setImmixAlertsOnCamera}
                    setSureViewAlertsOnCamera={setSureViewAlertsOnCamera}
                    setSendingImmixTest={setSendingImmixTest}
                    setImmixTestSuccessDialogOpen={
                      setImmixTestSuccessDialogOpen
                    }
                    setImmixTestFailDialogOpen={setImmixTestFailDialogOpen}
                    setSrcImages={setSrcImages}
                    setCameraInfoRow={setCameraInfoRow}
                    width={width}
                    permissions={permissions}
                    cameraWebhooksChangesMade={cameraWebhooksChangesMade}
                    cameraWebhooksPatchObject={cameraWebhooksPatchObject}
                    recipientsConfiguredData={recipientsConfiguredData}
                    titlesArray={titlesArray}
                    setRecipientsConfiguredData={setRecipientsConfiguredData}
                    setTitlesArray={setTitlesArray}
                    actionRulesData={actionRulesData}
                    setActionRulesData={setActionRulesData}
                    actionRulesTitlesArray={actionRulesTitlesArray}
                    setActionRulesTitlesArray={setActionRulesTitlesArray}
                    applicationsConfiguredData={applicationsConfiguredData}
                    axisFinished={axisFinished}
                    timeTablesData={timeTablesData}
                    setTimeTablesData={setTimeTablesData}
                    iPNotifyData={iPNotifyData}
                    setIPNotifyData={setIPNotifyData}
                    actionHandlerData={actionHandlerData}
                    setActionHandlerData={setActionHandlerData}
                    cameraWebhookData={cameraWebhookData}
                    setCameraWebhookData={setCameraWebhookData}
                    setCameraWebhooksPatchObject={setCameraWebhooksPatchObject}
                    setCameraWebhooksChangesMade={setCameraWebhooksChangesMade}
                    productsData={productsData}
                    setProductsData={setProductsData}
                    allTabsOpen={allTabsOpen}
                    setAllTabsOpen={setAllTabsOpen}
                    backupProductsData={backupProductsData}
                    setBackupProductsData={setBackupProductsData}
                    setSentinelAlertsOnCamera={setSentinelAlertsOnCamera}
                    sentinelAlerts={sentinelAlerts}
                    setAddSentinelAlertDialogOpen={
                      setAddSentinelAlertDialogOpen
                    }
                    setBoldAlertsOnCamera={setBoldAlertsOnCamera}
                    boldAlerts={boldAlerts}
                    setAddBoldAlertDialogOpen={setAddBoldAlertDialogOpen}
                    setPatriotAlertsOnCamera={setPatriotAlertsOnCamera}
                    patriotAlerts={patriotAlerts}
                    setAddPatriotAlertDialogOpen={setAddPatriotAlertDialogOpen}
                    setUSMonitoringAlertsOnCamera={
                      setUSMonitoringAlertsOnCamera
                    }
                    uSMonitoringAlerts={uSMonitoringAlerts}
                    setAddUSMonitoringAlertDialogOpen={
                      setAddUSMonitoringAlertDialogOpen
                    }
                  />
                )}
              </div>
              <div className={"absolute top-[10px] right-[250px]"}>
                {editMode && clickedTab !== "seven" && (
                  <EditCameraCancelButton
                    setEditMode={setEditMode}
                    clickedTab={clickedTab}
                    setActiveTab={setActiveTab}
                    siteInfo={siteInfo}
                    cameraGeneralInfo={cameraGeneralInfo}
                    setCameraGeneralInfo={setCameraGeneralInfo}
                    cameraGeneralInfoBackup={cameraGeneralInfoBackup}
                    setPatchObject={setPatchObject}
                    immixAlerts={immixAlerts}
                    sureviewAlerts={sureviewAlerts}
                    setAddImmixAlertDialogOpen={setAddImmixAlertDialogOpen}
                    setAddSureviewAlertDialogOpen={
                      setAddSureviewAlertDialogOpen
                    }
                    activeProducts={activeProducts}
                    setBackdropLoadingScreenOn={setBackdropLoadingScreenOn}
                    setSendingImmixTest={setSendingImmixTest}
                    setImmixTestSuccessDialogOpen={
                      setImmixTestSuccessDialogOpen
                    }
                    setImmixTestFailDialogOpen={setImmixTestFailDialogOpen}
                    recipientsConfiguredData={recipientsConfiguredData}
                    titlesArray={titlesArray}
                    setRecipientsConfiguredData={setRecipientsConfiguredData}
                    setTitlesArray={setTitlesArray}
                    actionRulesData={actionRulesData}
                    setActionRulesData={setActionRulesData}
                    actionRulesTitlesArray={actionRulesTitlesArray}
                    setActionRulesTitlesArray={setActionRulesTitlesArray}
                    applicationsConfiguredData={applicationsConfiguredData}
                    axisFinished={axisFinished}
                    timeTablesData={timeTablesData}
                    setTimeTablesData={setTimeTablesData}
                    iPNotifyData={iPNotifyData}
                    setIPNotifyData={setIPNotifyData}
                    actionHandlerData={actionHandlerData}
                    setActionHandlerData={setActionHandlerData}
                    cameraWebhookData={cameraWebhookData}
                    productsData={productsData}
                    setProductsData={setProductsData}
                    allTabsOpen={allTabsOpen}
                    setAllTabsOpen={setAllTabsOpen}
                    backupProductsData={backupProductsData}
                    setBackupProductsData={setBackupProductsData}
                    sentinelAlerts={sentinelAlerts}
                    setAddSentinelAlertDialogOpen={
                      setAddSentinelAlertDialogOpen
                    }
                    boldAlerts={boldAlerts}
                    setAddBoldAlertDialogOpen={setAddBoldAlertDialogOpen}
                    patriotAlerts={patriotAlerts}
                    setAddPatriotAlertDialogOpen={setAddPatriotAlertDialogOpen}
                    uSMonitoringAlerts={uSMonitoringAlerts}
                    setAddUSMonitoringAlertDialogOpen={
                      setAddUSMonitoringAlertDialogOpen
                    }
                    navigate={navigate}
                  />
                )}
              </div>
            </div>
            <div
              className={
                !navBarCollapsed
                  ? "absolute top-[617px] left-[150px] min-w-[1250px] right-[1px] bg-white"
                  : "absolute top-[617px] left-[75px] min-w-[1250px] right-[1px] bg-white"
              }
            >
              <EditCameraTabs
                clickedTab={clickedTab}
                setClickedTab={setClickedTab}
                editMode={editMode}
                setActiveTab={setActiveTab}
                siteInfo={siteInfo}
                cameraGeneralInfo={cameraGeneralInfo}
                setCameraGeneralInfo={setCameraGeneralInfo}
                setPatchObject={setPatchObject}
                setGeneralInfoChangesMade={setGeneralInfoChangesMade}
                immixAlertsOnCamera={immixAlertsOnCamera}
                sureViewAlertsOnCamera={sureViewAlertsOnCamera}
                immixAlerts={immixAlerts}
                setActiveChangeMade={setActiveChangeMade}
                sureviewAlerts={sureviewAlerts}
                setAddImmixAlertDialogOpen={setAddImmixAlertDialogOpen}
                setAddSureviewAlertDialogOpen={setAddSureviewAlertDialogOpen}
                streamsObject={streamsObject}
                setActiveProducts={setActiveProducts}
                activeProducts={activeProducts}
                axisCamera={axisCamera}
                recipientsConfiguredData={recipientsConfiguredData}
                titlesArray={titlesArray}
                navigate={navigate}
                setBackdropLoadingScreenOn={setBackdropLoadingScreenOn}
                setRecipientsConfiguredData={setRecipientsConfiguredData}
                setTitlesArray={setTitlesArray}
                actionRulesData={actionRulesData}
                setActionRulesData={setActionRulesData}
                actionRulesTitlesArray={actionRulesTitlesArray}
                setActionRulesTitlesArray={setActionRulesTitlesArray}
                applicationsConfiguredData={applicationsConfiguredData}
                mobotixCamera={mobotixCamera}
                timeTablesData={timeTablesData}
                setTimeTablesData={setTimeTablesData}
                iPNotifyData={iPNotifyData}
                setIPNotifyData={setIPNotifyData}
                actionHandlerData={actionHandlerData}
                setActionHandlerData={setActionHandlerData}
                axisFinished={axisFinished}
                setSendingImmixTest={setSendingImmixTest}
                setImmixTestSuccessDialogOpen={setImmixTestSuccessDialogOpen}
                setImmixTestFailDialogOpen={setImmixTestFailDialogOpen}
                cameraWebhookData={cameraWebhookData}
                setCameraWebhooksPatchObject={setCameraWebhooksPatchObject}
                setCameraWebhooksChangesMade={setCameraWebhooksChangesMade}
                productsData={productsData}
                setProductsData={setProductsData}
                allTabsOpen={allTabsOpen}
                setAllTabsOpen={setAllTabsOpen}
                setBackupProductsData={setBackupProductsData}
                sentinelAlertsOnCamera={sentinelAlertsOnCamera}
                sentinelAlerts={sentinelAlerts}
                setAddSentinelAlertDialogOpen={setAddSentinelAlertDialogOpen}
                boldAlertsOnCamera={boldAlertsOnCamera}
                boldAlerts={boldAlerts}
                setAddBoldAlertDialogOpen={setAddBoldAlertDialogOpen}
                patriotAlertsOnCamera={patriotAlertsOnCamera}
                patriotAlerts={patriotAlerts}
                setAddPatriotAlertDialogOpen={setAddPatriotAlertDialogOpen}
                uSMonitoringAlertsOnCamera={uSMonitoringAlertsOnCamera}
                uSMonitoringAlerts={uSMonitoringAlerts}
                setAddUSMonitoringAlertDialogOpen={
                  setAddUSMonitoringAlertDialogOpen
                }
              />
            </div>
            <div
              className={
                !navBarCollapsed
                  ? "absolute top-[666px] left-[150px] min-w-[1250px] right-[1px] h-[17vh]"
                  : "absolute top-[666px] left-[75px] min-w-[1250px] right-[1px] h-[17vh]"
              }
            >
              {activeTab}
            </div>
          </div>
        )}
      </div>
      <div className="justify-start">
        <NavBar
          navBarCollapsed={navBarCollapsed}
          setNavBarCollapsed={setNavBarCollapsed}
          props={{}}
        />
      </div>
    </div>
  );
};
