import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { handleChangePage } from "./SitesPageUtils/handleChangePage";
import { handleChangeRowsPerPage } from "./SitesPageUtils/handleChangeRowsPerPage";
import { AlertData, Order } from "../../Alerts/AlertsUtils/alertsInterfaces";
import {
  getComparator,
  stableSort,
} from "../../Alerts/AlertsUtils/alertsTableFunctions";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { createTheme } from "@mui/material/styles";
import { EnhancedSitesGroupTableHead } from "./EnhancedSitesGroupTableHead";
import useWindowDimensions from "../../../common/useWindowDimensions";
import { handleNavigateToAnalytics } from "./SitesPageUtils/handleNavigateToAnalytics";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { createArmedIcon } from "./SitesPageUtils/createArmedIcon";
import { createHealthIcon } from "./SitesPageUtils/createHealthIcon";
import { bandwidthConvertor } from "./SitesPageUtils/bandwidthConvertor";
import { Checkbox, TableHead } from "@mui/material";
import { createOptimalSettingsIcon } from "./SitesPageUtils/createOptimalSettingsIcon";
import { ArmButton } from "./ArmButton";
import { DisarmButton } from "./DisarmButton";
import { SyncButton } from "./SyncButton";
import { ActionButtonSitesPages } from "./ActionButtonSitesPages";

declare module "@mui/material/styles" {
  interface Palette {
    neutral: Palette["primary"];
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#FF8400",
    },
  },
});

export const SitesGroupTableSortable = (props: any) => {
  const {
    pageLoading,
    renderedRows,
    page,
    rowsPerPage,
    setPageLoading,
    navigate,
    setPage,
    setRowsPerPage,
    handleSelectAllClick,
    handleSingleCheckboxClick,
    isSelected,
    selected,
    setSelected,
    setSiteBreadcrumbs,
    setSiteScreenAlerts,
    setRenderedRows,
    setSiteScreenLoading,
  } = props;

  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState("camera_name");

  const handleRequestSort: any = (
    event: React.MouseEvent<unknown>,
    property: any
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const visibleRows = React.useMemo(
    () =>
      stableSort(renderedRows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [order, orderBy, page, rowsPerPage]
  );

  const { height } = useWindowDimensions();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const ITEM_HEIGHT = 48;

  const [selectedRow, setSelectedRow] = React.useState<any>([]);

  React.useEffect(() => {
    setCalculatedHeight(height - 205);
  }, [height]);

  const [calcuatedHeight, setCalculatedHeight] = React.useState(0);

  return (
    <div
      className="absolute 
    left-[405px] top-[150px] right-[5px]
    "
    >
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <div className="relative pt-[10px] pr-[20px] pb-[5px] z-50 flex justify-end flex">
          <p className="font-mulish font-bold pt-[5px] pr-[15px]">
            Bulk Action:
          </p>
          <ActionButtonSitesPages
            selected={selected}
            renderedRows={renderedRows}
            setSiteScreenLoading={setSiteScreenLoading}
            groupId={renderedRows[0]?.breadcrumbs[0].id}
            setPageLoading={setPageLoading}
            setSelected={setSelected}
            setRenderedRows={setRenderedRows}
            setSiteBreadcrumbs={setSiteBreadcrumbs}
            setSiteScreenAlerts={setSiteScreenAlerts}
            navigate={navigate}
          />
        </div>
        <TableContainer sx={{ maxHeight: calcuatedHeight }}>
          <Table
            stickyHeader
            sx={{
              postion: "absolute",
              top: 150,
              // minWidth: "1030px",
              minWidth: "1304px",
              fontFamily: "Mulish",
            }}
          >
            {!renderedRows[0] ? (
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      fontSize: "12px",
                      fontFamily: "mulish",
                      fontWeight: "Bold",
                    }}
                  >
                    Site Name
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: "12px",
                      fontFamily: "mulish",
                      fontWeight: "Bold",
                    }}
                  >
                    Armed
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: "12px",
                      fontFamily: "mulish",
                      fontWeight: "Bold",
                    }}
                  >
                    Cameras
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: "12px",
                      fontFamily: "mulish",
                      fontWeight: "Bold",
                    }}
                  >
                    Health
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: "12px",
                      fontFamily: "mulish",
                      fontWeight: "Bold",
                    }}
                  >
                    Optimal Settings
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: "12px",
                      fontFamily: "mulish",
                      fontWeight: "Bold",
                    }}
                  >
                    Schedules
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: "12px",
                      fontFamily: "mulish",
                      fontWeight: "Bold",
                    }}
                  >
                    Bandwidth Usage
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: "12px",
                      fontFamily: "mulish",
                      fontWeight: "Bold",
                    }}
                  >
                    Synced
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: "12px",
                      fontFamily: "mulish",
                      fontWeight: "Bold",
                    }}
                  >
                    Active
                  </TableCell>
                </TableRow>
              </TableHead>
            ) : (
              <EnhancedSitesGroupTableHead
                onRequestSort={handleRequestSort}
                orderBy={orderBy}
                order={order}
                onSelectAllClick={handleSelectAllClick}
              />
            )}
            <TableBody>
              {visibleRows.map((row: any, index: any) => {
                const isItemSelected = isSelected(row.id);
                return (
                  <TableRow
                    hover
                    onClick={() => {
                      setSelectedRow(row);
                    }}
                    tabIndex={-1}
                    key={`${row.name} ${row.index}`}
                    sx={{
                      cursor: "pointer",
                      fontFamily: "Mulish",
                    }}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        onClick={(event: any) =>
                          handleSingleCheckboxClick(event, row.id)
                        }
                      />
                    </TableCell>
                    <TableCell
                      align="left"
                      onClick={() => {
                        navigate(`/sites/aboutsite/${row.id}`);
                        handleClose();
                      }}
                      sx={{
                        fontFamily: "Mulish",
                        fontSize: 12,
                        width: "210px",
                      }}
                    >
                      {row.name}
                    </TableCell>
                    <TableCell
                      align="left"
                      onClick={() => {
                        navigate(`/sites/aboutsite/${row.id}`);
                        handleClose();
                      }}
                      sx={{
                        fontFamily: "Mulish",
                        width: "100px",
                      }}
                    >
                      {createArmedIcon(row.armed, row.armed_status)}
                    </TableCell>
                    <TableCell
                      align="left"
                      onClick={() => {
                        navigate(`/sites/aboutsite/${row.id}`);
                        handleClose();
                      }}
                      sx={{
                        fontFamily: "Mulish",
                        fontSize: 13,
                        width: "100px",
                      }}
                    >
                      {row.cameras}
                    </TableCell>
                    <TableCell
                      align="left"
                      onClick={() => {
                        navigate(`/sites/aboutsite/${row.id}`);
                        handleClose();
                      }}
                      sx={{
                        fontFamily: "Mulish",
                        fontSize: 13,
                        width: "100px",
                      }}
                    >
                      {createHealthIcon(row.health, row.error, row.warning)}
                    </TableCell>
                    <TableCell
                      align="left"
                      onClick={() => {
                        navigate(`/sites/aboutsite/${row.id}`);
                        handleClose();
                      }}
                      sx={{
                        fontFamily: "Mulish",
                        fontSize: 13,
                        width: "300px",
                      }}
                    >
                      {createOptimalSettingsIcon(
                        row.requirements_bad,
                        row.requirements_warning
                      )}
                    </TableCell>
                    <TableCell
                      align="left"
                      onClick={() => {
                        navigate(`/sites/aboutsite/${row.id}`);
                        handleClose();
                      }}
                      sx={{
                        fontFamily: "Mulish",
                        width: "105px",
                      }}
                    >
                      {row.schedules ? (
                        <CheckCircleIcon color="success" fontSize="small" />
                      ) : (
                        <CancelIcon color="error" fontSize="small" />
                      )}
                    </TableCell>
                    <TableCell
                      align="left"
                      onClick={() => {
                        navigate(`/sites/aboutsite/${row.id}`);
                        handleClose();
                      }}
                      sx={{
                        fontFamily: "Mulish",
                        fontSize: 13,
                        width: 300,
                      }}
                    >
                      {row.bandwidth
                        ? bandwidthConvertor(row.bandwidth)
                        : "N/A"}
                    </TableCell>
                    <TableCell
                      align="left"
                      onClick={() => {
                        navigate(`/sites/aboutsite/${row.id}`);
                        handleClose();
                      }}
                      sx={{
                        fontFamily: "Mulish",
                        width: "105px",
                      }}
                    >
                      {row.deployed ? (
                        <CheckCircleIcon color="success" fontSize="small" />
                      ) : (
                        <CancelIcon color="error" fontSize="small" />
                      )}
                    </TableCell>
                    <TableCell
                      align="left"
                      onClick={() => {
                        navigate(`/sites/aboutsite/${row.id}`);
                        handleClose();
                      }}
                      sx={{
                        fontFamily: "Mulish",
                        width: 100,
                      }}
                    >
                      {row.active ? (
                        <CheckCircleIcon color="success" fontSize="small" />
                      ) : (
                        <CancelIcon color="error" fontSize="small" />
                      )}
                    </TableCell>
                    <TableCell>
                      <IconButton
                        aria-label="more"
                        id="long-button"
                        aria-controls={open ? "long-menu" : undefined}
                        aria-expanded={open ? "true" : undefined}
                        aria-haspopup="true"
                        onClick={handleClick}
                      >
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                        id="long-menu"
                        MenuListProps={{
                          "aria-labelledby": "long-button",
                        }}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        PaperProps={{
                          style: {
                            maxHeight: ITEM_HEIGHT * 4.5,
                            width: "20ch",
                          },
                        }}
                      >
                        <MenuItem
                          key="view-edit-site"
                          onClick={() => {
                            navigate(`/sites/aboutsite/${row.id}`);
                            handleClose();
                          }}
                        >
                          View/Edit Site
                        </MenuItem>
                        <MenuItem
                          key="site-analytics"
                          onClick={() => {
                            handleNavigateToAnalytics(
                              selectedRow.name,
                              selectedRow.breadcrumbs[0].id,
                              selectedRow.id,
                              navigate
                            );
                            handleClose();
                          }}
                        >
                          Site Analytics
                        </MenuItem>
                        <MenuItem
                          key="site-analytics"
                          onClick={() => {
                            navigate(
                              `/alerts/?group=${selectedRow.breadcrumbs[0].id}&site=${selectedRow.id}`
                            );
                            handleClose();
                          }}
                        >
                          Site Alerts
                        </MenuItem>
                        <MenuItem
                          key="site-analytics"
                          onClick={() => {
                            navigate(`/actionlogs/${selectedRow.id}/`);
                            handleClose();
                          }}
                        >
                          Action Logs
                        </MenuItem>
                      </Menu>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={renderedRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          // sx={{display:"flex", justifyContent:"left"}}
          onPageChange={(event: unknown, newPage: number) =>
            handleChangePage(event, newPage, setPage)
          }
          onRowsPerPageChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            handleChangeRowsPerPage(event, setRowsPerPage, setPage)
          }
        />
      </Paper>
    </div>
  );
};
